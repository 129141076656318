import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { initializeAuth, initializeFirestore } from "../utils/firebaseConfig";
import { User } from "@shared/types";
import { captureException, captureMessage } from "../utils/logging";
import axios from "axios";
import { setupConfig } from "./apiUtils";
import { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

type UserRegistration = {
  uid: string;
  email: string;
};

export { useSendFeedback, useFetchUserById, useCreateUser, useUpdateUserMutation, useLogUserActivity, useLogActivityOnMount };

// Generalized Send Feedback
const useSendFeedback = () => {
  return useMutation({
    mutationFn: async (feedbackData: Record<string, any>): Promise<string> => {
      const apiUrl = `${process.env.REACT_APP_INTERNAL_API_URL}/v1/feedback`;
      try {
        const config = await setupConfig();
        const { data } = await axios.post(apiUrl, feedbackData, config);
        return data as string;
      } catch (error) {
        captureException("error sending feedback", error as Error);
        throw error;
      }
    },
  });
};

interface ActivityData {
  event_name: string;
  metadata?: Record<string, unknown>;
  path?: string;
}

const useLogUserActivity = () => {
  const location = useLocation();

  return useMutation({
    mutationFn: async (activityData: ActivityData): Promise<string> => {
      const apiUrl = `${process.env.REACT_APP_INTERNAL_API_URL}/v1/user_activity`;
      try {
        const config = await setupConfig();
        const { data } = await axios.post(apiUrl, {
          ...activityData,
          path: activityData.path || location.pathname
        }, config);
        return data as string;
      } catch (error) {
        captureException("error logging user activity", error as Error);
        throw error;
      }
    },
  });
};


const useLogActivityOnMount = (activityData: ActivityData) => {
  const { mutate: logUserActivity } = useLogUserActivity();
  const hasLogged = useRef(false);
  const location = useLocation();
  
  useEffect(() => {
    if (!hasLogged.current) {
      logUserActivity({
        ...activityData,
        path: activityData.path || location.pathname
      });
      hasLogged.current = true;
    }
  }, []);
}

const useFetchUserById = (uid: string) => {
  return useQuery<User, Error>({
    queryKey: ["user", uid],
    queryFn: async () => fetchUserByIdInFirestore(uid),
    enabled: !!uid,
  });
};

const useCreateUser = () => {
  return useMutation({
    mutationFn: async (user: UserRegistration) => {
      await createUserInFirestore(user);
    },
  });
};

const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (user: Partial<User>) => {
      await updateUserInFirestore(user);
    },
    onSuccess: () => {
      //TODO: use setQueryData to update the user in the cache
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });
};

const getEmailFromFirebaseAuth = () => {
  const auth = initializeAuth();
  const user = auth.currentUser;
  if (user) {
    const email = user.email;
    console.log("Email:", email);
    return email;
  } else {
    captureMessage("User not authenticated");
    return null;
  }
};

const getUidFromFirebaseAuth = () => {
  const auth = initializeAuth();
  const user = auth.currentUser;
  if (user) {
    const uid = user.uid;
    console.log("uid:", uid);
    return uid;
  } else {
    captureMessage("getUidFromFirebaseAuth: User not authenticated");
    return null;
  }
};

const createUserInFirestore = async (user: UserRegistration) => {
  const { uid, email } = user;
  if (!uid) throw new Error("uid not exist");
  if (!email) throw new Error("email not exist");

  try {
    const db = initializeFirestore();
    const userRef = doc(db, "users", uid);
    const userData: User = {
      id: uid,
      email: email,
      name: "",
      stripeCustomerId: "",
      tierId: "3",
    };
    await setDoc(userRef, userData);
    console.log("User document created successfully");
    return userRef;
  } catch (error) {
    captureException("Error creating user document", error as Error);
    throw error;
  }
};

export const fetchUserByIdInFirestore = async (uid: string): Promise<User> => {
  try {
    const db = initializeFirestore();
    // const uid = getUidFromFirebaseAuth();
    if (!uid) throw new Error("User not authenticated");

    const userRef = doc(db, "users", uid);
    console.log("userRef", userRef);
    const userSnapshot = await getDoc(userRef);

    console.log("userSnapshot", userSnapshot);
    let user: User;

    if (userSnapshot.exists()) {
      user = {
        id: userSnapshot.id,
        name: "",
        email: "",
        stripeCustomerId: "",
        tierId: "3",
        ...userSnapshot.data(),    
      };
    } else {
      // Create user document if it doesn't exist
      const email = getEmailFromFirebaseAuth();

      if (!email) {
        throw new Error("User not authenticated");
      }

      const createdUserRef = await createUserInFirestore({ uid, email });
      const createdUserSnapshot = await getDoc(createdUserRef);

      user = {
        id: createdUserSnapshot.id,
        name: "",
        email: "",
        stripeCustomerId: "",
        tierId: "3",
        ...createdUserSnapshot.data(),
      };
    }

    return user;
  } catch (error) {
    captureException("Error fetching user from Firestore", error as Error);
    throw error;
  }
};

export const updateUserInFirestore = async (user: Partial<User>) => {
  const uid = getUidFromFirebaseAuth();
  if (!uid) throw new Error("User ID is required");

  const db = initializeFirestore();

  const userRef = doc(db, "users", uid);
  await setDoc(userRef, user, { merge: true });
};