import { Link } from "react-router-dom";
import { cn } from "@/web/lib/utils"

interface BackProps {
  link: string;
  text: string;
  className?: string;
}

export function Back({ link, text, className }: BackProps) {
  return (
    <Link to={link} className={cn("block py-2 text-gray-400 text-sm", className)}>
    &#8592; {text}
  </Link>
  )
}
