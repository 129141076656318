// ProjectCard.tsx
import { Link } from "react-router-dom";
import { Badge } from "@shadcn/badge";
import { Project } from "@shared/types";
import { useHeadlessToHtml } from "./useHeadlessToHtml";

interface ProjectCardProps {
  project: Project;
}

export function ProjectCard({ project }: ProjectCardProps) {
  const html = useHeadlessToHtml(project?.details) ?? "";

  return (
    <Link
      to={`/share/${project.shareHash}`}
      key={project.id}
      className="rounded-lg border border-color-gray-200 p-6 shadow-sm hover:shadow-lg
      hover:scale-105 transition-transform ease-in-out duration-100"
    >
      <h3 className="text-3xl font-semibold tracking-tight mb-4 mt-4">{project.name}</h3>
      <div className="min-h-[25px]">
        {project.tags &&
          project.tags.map((tag) => (
            <Badge key={`${project.id}-${tag}`} variant="outline" className="mr-2 font-normal rounded border-gray-100">
              {tag}
            </Badge>
          ))}
      </div>
      <div className="relative mt-4 h-[300px] overflow-hidden">
        {project.details && <div dangerouslySetInnerHTML={{ __html: html }} />}
        <div className="absolute bottom-0 left-0 right-0 h-[100px] bg-gradient-to-b from-transparent to-white" />
      </div>
    </Link>
  );
}
