import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/tooltip";

export default function Required() {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger tabIndex={-1}>
          <div className="h-5 w-5 bg-slate-100 grid place-content-center rounded-full ml-2">
            <span className="text-sm font-bold leading-none mt-1">*</span>
          </div>
        </TooltipTrigger>
        <TooltipContent className="font-semibold">Required</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
