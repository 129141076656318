import { useKeypress } from "@/hooks/useKeypress";
import { useEditPromptAnswerById } from "@/queries/promptAnswers";
import { PromptAnswer } from "@shared/types";
import { Button } from "@shadcn/button";
import { PromptQuestion } from "@shared/components/prompts/question";
import { Form } from "@shared/components/shadcn/form";
import { useForm } from "react-hook-form";

interface AnswerEditModeProps {
  formInitialData: PromptAnswer;
  toggleEditMode: () => void;
}

export const AnswerEditMode = ({ formInitialData: prompt, toggleEditMode }: AnswerEditModeProps) => {
  const form = useForm({
    defaultValues: prompt.questions.reduce((acc, q, index) => {
      acc[`question-${index}-${q.type}`] = q.answer;
      return acc;
    }, {} as Record<string, string>)
  });

  const { mutate: editAnswer } = useEditPromptAnswerById();
  useKeypress("Escape", toggleEditMode);

  const handleSave = (values: Record<string, string>) => {
    const questions = prompt.questions.map((q, index) => ({
      ...q,
      answer: values[`question-${index}-${q.type}`] || "",
    }));
    const finalForm: PromptAnswer = {
      ...prompt,
      questions,
      aiResponse: "",
    };
    if (finalForm.id === undefined) return <div>Error Prompt ID not found.</div>;

    editAnswer({ promptAnswerId: finalForm.id, updatedPromptAnswer: finalForm });
    toggleEditMode();
  };

  const handleCancel = () => {
    toggleEditMode();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSave)}>
        <div className="flex justify-between items-center mt-10">
          <span className="text-lg text-gray-500">{prompt.category}</span>
          <div>
            <Button className="mr-4" type="submit">
              Update
            </Button>
            <Button variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
        <h1 className="text-4xl mt-2 mb-2 text-primary">{prompt.title}</h1>
        <p className="text-gray-500">{prompt.description}</p>
        <div className="flex flex-col gap-8 my-16">
        {prompt.questions.map((question, index) => (
          <PromptQuestion key={`${question.question}-${index}`} question={question} index={index} />
        ))}
        </div>
        <Button className="mt-10 mr-4" type="submit">
          Update
        </Button>
        <Button className="mt-10" variant="outline" onClick={handleCancel}>
          Cancel
        </Button>
        <em className="ml-4">Update your answers will remove any Lifehack AI Coach responses.</em>
      </form>
    </Form>
  );
};
