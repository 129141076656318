import { useMediaQuery } from "react-responsive";
import useSettingsStore from "@/store/settings";

const useSidebarVisibility = () => {
  const md = useMediaQuery({ maxWidth: 767 });
  const { sidebarHidden, toggleSidebarHidden } = useSettingsStore();

  // Return a function that can be called externally
  return () => {
    if (!sidebarHidden && md) {
      toggleSidebarHidden();
    }
  };
};

export default useSidebarVisibility;
