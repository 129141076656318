import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@shadcn/alert-dialog";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@shadcn/card";

import { CalendarClock, FastForward, Info, Trash } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useFetchActiveProjects } from "../../../queries/projects";
import { useDeleteTimeblockMutation, useFetchTimeblocks } from "../../../queries/timeblocks";
import { Project, Timeblock } from "@shared/types";
import { upcomingTimeblocksGroupByDate } from "../../../utils/timeblocks";
import { Skeleton } from "@shadcn/skeleton";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@shadcn/tooltip";
import { renderTag } from "../../helpers/renderTag";
import useSidebarVisibility from "./useSidebarVisibility";

function RemainingTime({ startTime, endTime }: { startTime: Date; endTime: Date }) {
  const isWithinTimeblock = (startTime: Date, endTime: Date) => {
    const now = new Date();
    return now >= startTime && now <= endTime;
  };

  const getRemainingTime = (endTime: Date) => {
    const now = new Date();
    const diffInMilliseconds = endTime.getTime() - now.getTime();

    const seconds = Math.floor((diffInMilliseconds / 1000) % 60);
    const minutes = Math.floor((diffInMilliseconds / (1000 * 60)) % 60);

    if (minutes > 0 || seconds > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return null;
    }
  };

  if (!isWithinTimeblock(startTime, endTime)) {
    return null;
  }

  const [remainingTime, setRemainingTime] = useState(getRemainingTime(endTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getRemainingTime(endTime));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <div className="text-xs text-gray-600">{remainingTime ? `🕐 Time left: ${remainingTime}` : null}</div>;
}

export default function TimeblockSidebar() {
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(true);
  // const { data: groupedTimeblocks, isLoading } =
  //   useFetchUpcomingTimeblockgroupByDateQuery({ uid });
  const { isPending, data: timeblocks } = useFetchTimeblocks();
  const groupedTimeblocks = timeblocks && upcomingTimeblocksGroupByDate(timeblocks);

  // const { data: activeProjects } = useGetActiveProjectsQuery({ uid });
  const { data: activeProjects } = useFetchActiveProjects();
  // const [deleteTimeblock] = useDeleteTimeblockMutation();
  const deleteTimeblock = useDeleteTimeblockMutation();
  const sidebarHidden = useSidebarVisibility();

  useEffect(() => {
    console.log("grouptimebocks", groupedTimeblocks);
  }, [groupedTimeblocks]);

  // useEffect(() => {
  //   // setIsLoading(true);
  //   // Promise.all([
  //     dispatch(fetchAllTimeblocks());
  //     dispatch(fetchActiveProjects());
  //   // ]).then(() => setIsLoading(false));
  // }, [dispatch]);

  const formatTime = (date: Date) => {
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${hours}:${minutes}`;
  };

  const today = new Date();
  const formatDate = (date: Date) => {
    return date.toDateString(); // 'Wed Jun 21 2023'
  };

  // const groupedTimeblocks = useSelector((state: RootState) =>
  //   selectGroupedTimeblocksByDate(state)
  // );

  const handleDeleteTimeblock = (event: React.MouseEvent, timeblockId: string) => {
    event.stopPropagation();
    // dispatch(deleteTimeblockFromFirestore(timeblockId));
    // deleteTimeblock({ id: timeblockId });
    deleteTimeblock.mutate(timeblockId);
  };

  const getGapTime = (endTime: Date, nextStartTime: Date) => {
    const diffInMilliseconds = nextStartTime.getTime() - endTime.getTime();

    const minutes = Math.floor((diffInMilliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((diffInMilliseconds / (1000 * 60 * 60)) % 24);

    return `${hours}h ${minutes}m`;
  };

  function computeTimeBlockData(blocks: Timeblock[], activeProjects: Project[], index: number, timeblock: Timeblock) {
    const project = activeProjects.find((project) => project.id === timeblock.projectId);
    const startTime = new Date(timeblock.startTime);
    const endTime = new Date(startTime.getTime() + timeblock.duration * 60 * 60 * 1000);

    const nextTimeBlock = index < blocks.length - 1 ? blocks[index + 1] : null;
    let nextStartTime;
    let isLastTimeBlock = false;
    if (nextTimeBlock) {
      nextStartTime = new Date(nextTimeBlock.startTime);
    } else if (index === blocks.length - 1) {
      nextStartTime = new Date(startTime);
      nextStartTime.setHours(24, 0, 0, 0); // Next day's start time (midnight)
      isLastTimeBlock = true;
    }
    const gapTime = nextStartTime && nextStartTime > endTime ? getGapTime(endTime, nextStartTime) : null;

    return {
      project,
      startTime,
      endTime,
      isLastTimeBlock,
      gapTime,
    };
  }

  // Function to render the AlertDialog for deleting a timeblock
  function renderAlertDialog(timeblockId: string) {
    return (
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Trash size={16} className="inline opacity-0 group-hover:opacity-100 cursor-pointer" />
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>This will delete your timeblock.</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={(event) => handleDeleteTimeblock(event, timeblockId)}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  // Function to render each timeblock
  function renderTimeblock(timeblock: Timeblock, index: number, blocks: Timeblock[], activeProjects: Project[]) {
    const { project, startTime, endTime, isLastTimeBlock, gapTime } = computeTimeBlockData(
      blocks,
      activeProjects,
      index,
      timeblock
    );

    return (
      <div key={timeblock.id}>
        <Card
          key={timeblock.id}
          className={`relative mt-4 mb-4 
          ${timeblock.type === "adhoc" && "bg-gray-100"}
          group ${new Date() > endTime ? "text-gray-600" : ""}`}
        >
          <div className="absolute right-4 top-2">{renderAlertDialog(timeblock.id)}</div>
          <CardHeader
            className={`${timeblock.type === "project" && "cursor-pointer"} `}
            onClick={() => {
              timeblock.type === "project" && navigate(`/projects/${timeblock.projectId}`);
              sidebarHidden();
            }}
          >
            <div className="flex justify-between items-center">
              <CardTitle>
                <div className="font-bold text-sm md:text-base">
                  {`${formatTime(startTime)} → ${formatTime(endTime)}`}
                </div>
              </CardTitle>
            </div>
            <CardDescription>
              <span className="text-base md:text-lg">
                {timeblock.type === "adhoc" && <FastForward size={16} className="inline mr-2" />}
                {timeblock.type === "adhoc" && timeblock.adhocName}
                {timeblock.type === "project" && project && project.name}
              </span>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <RemainingTime startTime={startTime} endTime={endTime} />
          </CardContent>
        </Card>

        {gapTime && (
          <div className="flex flex-row items-center mt-3 ml-6">
            <div className="text-gray-400 text-center mr-2" style={{ lineHeight: "0.7" }}>
              •<br />•<br />•<br />•
            </div>
            <span className="text-sm text-gray-500">{isLastTimeBlock ? `${gapTime} - 🌙 Midnight` : gapTime}</span>
          </div>
        )}
      </div>
    );
  }

  // Function to render each grouped timeblocks
  function renderGroupedTimeblocks(groupedTimeblocks: Record<string, Timeblock[]>) {
    return Object.entries(groupedTimeblocks).map(([date, blocks]) => (
      <div key={date} className="mt-4">
        <h3 className="text-sm font-bold text-gray-600">
          {date}
          {formatDate(today) === date
            ? renderTag({
                label: "TODAY",
                className: "text-xs ml-2 pl-2 pr-2 font-bold rounded-full",
              })
            : null}
        </h3>
        {activeProjects && blocks.map((timeblock, index) => renderTimeblock(timeblock, index, blocks, activeProjects))}
      </div>
    ));
  }

  function displayLoading() {
    return (
      <div className="w-full h-full flex justify-center items-center p-6">
        <div>
          <Skeleton className="h-6 w-64 rounded-md mb-2" />
          <Skeleton className="h-4 w-full rounded-md mb-2" />
          <Skeleton className="h-6 w-full rounded-md mb-2 pl-4" />
          <Skeleton className="h-4 w-full rounded-md mb-2" />
        </div>
      </div>
    );
  }

  function displayEmpty() {
    return (
      <div className="w-full max-w-400px mx-auto p-5 mt-10 mb-10 text-center">
        <div className="bg-gray-200 w-24 h-24 pt-6 rounded-full mb-5 inline-block text-center">
          <CalendarClock size={48} className="inline" />
        </div>
        <div className="text-center">
          <div className="block font-bold">
            Schedule a Focus Block to see it on your Timeline&nbsp;&nbsp;
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info size={20} className="inline align-text-top cursor-pointer text-yellow-600  " />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Focus Blocks are scheduled blocks of time dedicated to completing a specific task.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <a
            href="https://help.lifehack.org/article/118-what-are-focus-blocks"
            target="_blank"
            rel="noreferrer"
            className="text-sm text-gray-500 underline"
          >
            Learn More
          </a>
        </div>
      </div>
    );
  }
  // Then, inside your main component...
  return (
    <>
      {isPending
        ? displayLoading()
        : groupedTimeblocks && Object.keys(groupedTimeblocks).length !== 0
          ? renderGroupedTimeblocks(groupedTimeblocks)
          : displayEmpty()}
    </>
  );
}
