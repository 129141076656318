/**
 * v0 by Vercel.
 * @see https://v0.dev/t/lXWtHiLsRkD
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import * as Sentry from "@sentry/react";
import { useRouteError } from "react-router-dom";
import { useState } from "react";
import UserMessagePopup from "@components/UserMessagePopup"; // Import the popup component

export function ErrorBoundary() {
  const error = useRouteError();
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility

//   const handleOpenPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  Sentry.captureException(error);

  return (
    <div>
      <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-md text-center">
          <div className="mx-auto h-12 w-12 text-primary" />
          <h1 className="mt-4 text-3xl font-bold tracking-tighter text-foreground sm:text-4xl">
            Oops, something went wrong!
          </h1>
          <p className="mt-4 text-muted-foreground">
            We&apos;re sorry, but an unexpected error has occurred. Please try again later or <a className="text-primary underline" href="mailto:support@lifehack.org">contact support</a> if the
            issue persists.
          </p>
          <div className="mt-6 flex flex-col gap-2">
            <a
              href="/"
              className="inline-flex items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              Go to Dashboard
            </a>
            {/* <button
              className="inline-flex items-center justify-center rounded-md border border-input bg-background px-4 py-2 text-sm font-medium shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:pointer-events-none disabled:opacity-50"
              onClick={handleOpenPopup}
            >
              Report Issue
            </button> */}
          </div>
        </div>
      </div>
      {showPopup && (
        <UserMessagePopup
          onClose={handleClosePopup}
          title="Report Issue"
          description="Report an issue to our team."
          placeholder="Where did you encounter this issue?"
        />
      )}
    </div>
  );
}