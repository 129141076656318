import * as Sentry from "@sentry/react";

export function captureException(message: string, error: Error) {
    console.error(message + ": " + error);
    Sentry.captureException(message + ": " + error);
}

export function captureMessage(message: string) {
    console.log(message);
    Sentry.captureMessage(message);
}