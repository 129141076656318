/**
 * v0 by Vercel.
 * @see https://v0.dev/t/B5LdzpFRWhq
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { useState } from "react";
import { Textarea } from "@shadcn/textarea";
import { Button } from "@shadcn/button";
import { useSendFeedback } from "@/queries/user"; // Updated import
import { X } from "lucide-react";
import { z } from "zod";
import { useToast } from "@shadcn/use-toast";

interface UserMessagePopupProps {
  onClose: () => void;
  title?: string;
  description?: string;
  successTitle?: string;
  successDescription?: string;
  placeholder?: string;
}

const feedbackSchema = z.object({
  feedback: z.string().min(1, "Feedback is required"),
  url: z.string().url(),
});

export default function UserMessagePopup({
  onClose,
  title = "Send us a message",
  description = "Anything we can help? We'd love to hear from you!",
  successTitle = "Your Message Has Been Sent",
  successDescription = "We appreciate your message and will respond promptly. Please check your inbox for our reply.",
  placeholder = "Your questions, comments, or suggestions...",
}: UserMessagePopupProps) {
  const [feedbackData, setFeedbackData] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<{ feedback?: string; rating?: string }>({});
  const { toast } = useToast();

  const mutation = useSendFeedback(); // Updated to use generalized hook

  const handleSubmit = () => {
    const currentUrl = window.location.href;
    feedbackData.url = currentUrl; // Ensure URL is part of feedback data

    const result = feedbackSchema.safeParse(feedbackData);

    if (!result.success) {
      const formErrors = result.error.format();
      setErrors({
        feedback: formErrors.feedback?._errors[0],
      });
      return;
    }

    mutation.mutate(feedbackData, {
      onSuccess: () => {
        toast({
          title: successTitle,
          description: successDescription,
        });
        onClose();
      },
    });
  };

  return (
    <div
      key="1"
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div className="bg-white rounded-lg max-w-md w-full p-6 space-y-4" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button aria-label="Close" onClick={onClose}>
            <X className="h-6 w-6" />
          </button>
        </div>
        <p>{description}</p>
        <Textarea
          className="border-gray-300"
          placeholder={placeholder}
          value={feedbackData.feedback || ""}
          onChange={(e) => {
            setFeedbackData((prev) => ({ ...prev, feedback: e.target.value }));
            setErrors((prev) => ({ ...prev, feedback: undefined }));
          }}
        />
        {errors.feedback && <p className="text-red-500">{errors.feedback}</p>}
        <Button className="w-full" onClick={handleSubmit}>Submit</Button>
      </div>
    </div>
  );
}