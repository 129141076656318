import { Link } from "react-router-dom";
import notfoundphoto from "@/images/404.png";

const NotFoundPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-white w-full">
      <div className="text-center p-8 bg-white">
      <img src={notfoundphoto} className="mx-auto w-64" />
        <h1 className="text-5xl font-argent font-bold lhgreen-text text-gray-500 tracking-tight leading-tight">Ooops! We couldn&apos;t find that page</h1>
        <p className="mt-4 text-xl text-gray-700">For further assistance, please email <a className="underline" href="mailto:support@lifehack.org">support</a>.</p>
        <Link to={`/`} className="block mt-6 p-3 text-gray-500 hover:text-black">
          &#8592; Back to Dashboard
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
