import { useState } from "react";
import { useEffect, useRef } from "react";
import { Input } from "@shadcn/input";
import { Label } from "@shadcn/label";
import { Switch } from "@shadcn/switch";
import { ProjectPullDown } from "./ProjectPullDown";

type Props = {
  defaultType?: "project" | "adhoc";
  defaultProjectId?: string;
  onTypeChange: (newType: "project" | "adhoc") => void;
  onAdhocNameChange: (newAdhocName: string) => void;
  onProjectIdChange: (newProjectId: string) => void;
};

function TimeBlockTypeToggle({
  defaultType = "project",
  defaultProjectId = "",
  onTypeChange,
  onAdhocNameChange,
  onProjectIdChange,
}: Props) {
  const [isAdhocBased, setIsAdhocBased] = useState(false);
  const adhocInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (defaultType === "adhoc") {
      setIsAdhocBased(true);
    } else {
      setIsAdhocBased(false);
    }
  }, [defaultType]);

  useEffect(() => {
    if (!defaultProjectId) {
      setIsAdhocBased(true);
    }
  }, [defaultProjectId]);

  useEffect(() => {
    if (isAdhocBased) {
      onTypeChange("adhoc");
    } else {
      onTypeChange("project");
    }
    if (isAdhocBased && adhocInputRef.current) {
      adhocInputRef.current.focus();
      adhocInputRef.current.value = "Quick Focus";
      onAdhocNameChange("Quick Focus");
      adhocInputRef.current.select();
    }
  }, [isAdhocBased]);

  function handleProjectPullDown(projectId: string) {
    onProjectIdChange(projectId);
  }

  return (
    <div className="flex items-center space-x-2">
      {!isAdhocBased && <ProjectPullDown project={defaultProjectId} onChange={handleProjectPullDown} />}
      {isAdhocBased && (
        <Input
          ref={adhocInputRef}
          placeholder="Instant Focus Block Name"
          onChange={(e) => onAdhocNameChange(e.target.value)}
        />
      )}
      <div className="flex items-center space-x-2">
        <Label htmlFor="timeblock-type-toggle">Initative</Label>
        <Switch
          id="timeblock-type-toggle"
          checked={isAdhocBased}
          onCheckedChange={() => setIsAdhocBased(!isAdhocBased)}
          className="data-[state=checked]:bg-secondary data-[state=unchecked]:bg-primary"
        />
        <Label htmlFor="timeblock-type-toggle">Quick</Label>
      </div>
    </div>
  );
}

export default TimeBlockTypeToggle;
