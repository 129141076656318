import { useEffect, useState } from "react";
import { buttonVariants } from "@shadcn/button";
import { cn } from "../../lib/utils";
import { UserAuthForm } from "./UserAuthForm";
import { User } from "firebase/auth";

import lifehackLogo from "../../../images/lifehack-logo.png";
import notebook from "../../../images/notebook.png";
import { Link } from "react-router-dom";

import { useAuthContext } from "@/providers/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoggerContext } from "@/providers/LoggerProvider";
import { ring } from "ldrs";
import { useDocumentTitle } from "usehooks-ts";

export default function Login() {
  const navigate = useNavigate();
  const { user, loading } = useAuthContext();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const { log } = useLoggerContext();
  const authToken = new URLSearchParams(window.location.search).get("authToken");

  useDocumentTitle("Login - Lifehack App");

  useEffect(() => {
    console.log(authToken);
    if (!loading && user) {
      log("auth", "login-success", { uid: user.uid });
      if (authToken && process.env.OAUTH_URL) {
        log("auth", "redirect-oauth", { uid: user.uid });
        redirectToOAuth(user, process.env.OAUTH_URL);
      } else {
        log("auth", "redirect-root", { uid: user.uid });
        const state = location.state;
        state?.from ? navigate(state.from) : navigate("/");
      }
    }
  }, [user, loading, authToken]);

  const redirectToOAuth = async (user: User, url: string) => {
    if (!user || !authToken) {
      return;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          auth_token: authToken,
          id_token: await user.getIdToken(),
          success: "true",
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      window.location = data.url;
    } catch (error) {
      log("auth", "failed-oauth-post-call", { uid: user.uid });
      setError("An error occurred while processing your request. Please try again later.");
    }
  };

  ring.register();

  return (
    <>
      <div className="container relative h-[100vh] flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <a
          href="https://www.lifehack.org"
          className={cn(buttonVariants({ variant: "ghost", size: "sm" }), "absolute right-4 top-4 md:right-8 md:top-8")}
        >
          Back to Homepage
        </a>
        <div className="relative hidden h-full flex-col bg-muted bg-lhgreen p-10 text-white dark:border-r lg:flex overflow-hidden">
          <div className="z-20 flex items-center">
            <img src={lifehackLogo} className="mt-4 mb-4 w-48" />
            <p className="text-base font-medium opacity-75">More Time. More Action. More Meaning.</p>
            <img src={notebook} className="w-full absolute bottom-0 left-8" />
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            {error && <div className="text-red-500">{error}</div>}
            {!loading && user && !error && (
              <div className="flex items-center space-x-2">
                <l-ring size="20" stroke="5" bg-opacity="0" speed="2" color="#666"></l-ring>
                <span className="text-lg">
                  Just a moment while we load your LifeHack App account with all the tools and resources...
                </span>
              </div>
            )}
            {!loading && !user && !error && (
              <>
                <div className="flex flex-col space-y-2 text-center">
                  <h1 className="text-4xl font-semibold tracking-tight font-argent">Login to LifeHack App</h1>
                  <p className="text-sm text-muted-foreground">
                    Enter your email and password. If you&apos;re a member but don&apos;t have an account yet, please{" "}
                    <a href="mailto:support@lifehack.org" className="underline" target="_blank">
                      contact us
                    </a>
                    .
                  </p>
                </div>
                <UserAuthForm />
                <Link to="/forgot-password" className="text-sm text-primary">
                  Forgot password?
                </Link>
              </>
            )}
            {error && (
              <div className="text-sm">
                Contact{" "}
                <a href="mailto:support@lifehack.org" className="underline text-primary">
                  support
                </a>{" "}
                if you need any assistance.
              </div>
            )}
            {/* <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}
