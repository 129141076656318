import { useFetchGoalByGoalId } from "@/queries/goals";
import { useFetchActiveProjectsByGoalId } from "@/queries/projects";
import { CardContent } from "@shadcn/card";
import { Link } from "react-router-dom";
import { InitiativeList } from "./InitiativeList";
import { NoProject } from "./NoProject";

interface BigNorthstarContentProps {
  goalId: string;
}

export function BigNorthstarContent({ goalId }: BigNorthstarContentProps) {
  const { data: goal } = useFetchGoalByGoalId(goalId);
  const { data: latestInitiatives, isLoading, error } = useFetchActiveProjectsByGoalId(goalId);

  return (
    <CardContent className="grid gap-6">
      <Link to={`/goals/${goalId}`}>
        <h2 className="font-argent font-bold hover:text-black/70 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800 leading-tight break-words hyphens-auto">{goal?.name}</h2>
      </Link>
      <div className="grid gap-4">
        {isLoading ? (
          <div>Loading latest initiatives...</div>
        ) : error ? (
          <div>Error loading initiatives.</div>
        ) : latestInitiatives && latestInitiatives.length > 0 ? (
          <InitiativeList initiatives={latestInitiatives.slice(0, 3)} />
        ) : (
          <NoProject goalId={goalId} />
        )}
      </div>
      {latestInitiatives && latestInitiatives.length > 0 && (
        <div className="flex items-center justify-between text-sm text-muted-foreground">
          <div>
            {latestInitiatives[0]?.updatedAt
              ? "Latest activity: " + new Date(latestInitiatives[0].updatedAt).toLocaleDateString()
              : ""}
          </div>
          {latestInitiatives.length > 3 && (
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <Link to={`/goals/${goalId}`} className="text-primary font-medium underline">
                View {latestInitiatives.length - 3} More Initiative{latestInitiatives.length - 3 > 1 ? "s" : ""}
              </Link>
            </div>
          )}
        </div>
      )}
    </CardContent>
  );
}