import { $createListItemNode, $createListNode } from "@lexical/list";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { $createTextNode, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";
import { useEffect, useState } from "react";
import { useFetchAiActionRecomendations } from "@/queries/ai";
import { useFetchProjectByProjectId } from "@/queries/projects";
import { useFetchGoalByProjectId } from "@/queries/goals";
import { dotSpinner } from "ldrs";
import { motion, AnimatePresence } from "framer-motion";

export const INSERT_AI_ACTION_ITEMS_COMMAND: LexicalCommand<undefined> = createCommand();

interface AiActionItemsPluginProps {
  projectId: string;
}

function FloatingAiMessage() {
  dotSpinner.register();

  return (
    <motion.div
      className="z-50 fixed inset-x-0 bottom-4 flex items-center justify-center pointer-events-none"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <div className=" bg-white text-slate-900 px-6 py-3 rounded-lg shadow-lg border border-gray-200">
        <p className="text-sm font-medium">
          <span className="mr-2">
            <l-dot-spinner size="10" speed="0.9" color="black" />
          </span>{" "}
          AI Copilot is thinking about possible actions based on your existing content...
        </p>
      </div>
    </motion.div>
  );
}

export const AiActionItemsPlugin = ({ projectId }: AiActionItemsPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const [userInput, setUserInput] = useState<string>("");
  const [showFloatingMessage, setShowFloatingMessage] = useState(false);
  const { data, error } = useFetchAiActionRecomendations(userInput);
  const { data: project } = useFetchProjectByProjectId(projectId);
  const { data: goal } = useFetchGoalByProjectId(projectId);

  useEffect(() => {
    const unregisterCommand = editor.registerCommand(
      INSERT_AI_ACTION_ITEMS_COMMAND,
      () => {
        editor.update(() => {
          const markdownString = $convertToMarkdownString(TRANSFORMERS);
          let processedMarkdownString = processMarkdown(markdownString, 4000);
          if (project && goal) {
            processedMarkdownString = "<goal>\n\n" + goal.name + "\n" + goal.details + "\n\n</goal>\n\n<project_name>\n\n" + project.name + "\n\n</project_name>\n\n<project_content>\n\n" + processedMarkdownString + "\n\n</project_content>";
          }
          setUserInput(processedMarkdownString);
          setShowFloatingMessage(true);
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );

    return () => {
      unregisterCommand();
    };
  }, [editor, project, goal]);

  useEffect(() => {
    if (data) {
      editor.update(() => {
        const actions = data.recommendations;
        const listNode = $createListNode("check");

        actions.map((action) => {
          const listItemNode = $createListItemNode();
          listItemNode.append($createTextNode(action));
          listNode.append(listItemNode);
        });

        $insertNodeToNearestRoot(listNode);
      });
      setShowFloatingMessage(false);
    }
  }, [data, editor]);

  useEffect(() => {
    if (error) {
      setShowFloatingMessage(false);
    }
  }, [error]);

  return <AnimatePresence>{showFloatingMessage && <FloatingAiMessage />}</AnimatePresence>;
};

function processMarkdown(markdownString: string, maxCharacters: number) {
  const truncatedMarkdown =
    markdownString.length > maxCharacters ? "..." + markdownString.slice(-maxCharacters) : markdownString;

  return truncatedMarkdown.replace(/^\s*$/gm, "");
}
