import { useNavigate } from 'react-router-dom';
import { useFetchPromptAnswerByIsDashboard } from "@/queries/promptAnswers"
import ProcessingScreen from "./ProcessingScreen"
import LoadingIndicator from "@components/loading-indicator/LoadingIndicator"
import DashboardReport from "./DashboardReport"
import { PushPermission } from "./PushPermission"
import { BigNorthstar } from "./BigNorthstar/BigNorthStar"
import AskCoach from "./AskCoach"
import { useEffect } from 'react';

export function Dashboard() {
  const navigate = useNavigate();
  const { data: promptAnswer, isLoading } = useFetchPromptAnswerByIsDashboard()

  useEffect(() => {
    if (!isLoading && !promptAnswer) {
      navigate('/onboarding');
    }
  }, [isLoading, promptAnswer, navigate]);

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (!promptAnswer) {
    return null;
  }

  if (promptAnswer && (promptAnswer.isWaitingHumanCoach === undefined || promptAnswer.isWaitingHumanCoach === true)) {
    return <ProcessingScreen />
  }

  return (
    <div className="p-2 md:p-6">
      {promptAnswer && promptAnswer.isWaitingHumanCoach === false && (
        <>
          <PushPermission />
          <BigNorthstar />
          <AskCoach />
          <DashboardReport prompt={promptAnswer} />
        </>
      )}
    </div>
  )
}
