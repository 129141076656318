import { Check, ChevronsUpDown } from "lucide-react";
import { useRef, useState, useMemo, useEffect } from "react";

import { cn } from "@shared/lib/utils";
import { Button } from "@shadcn/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@shadcn/command";
import { Popover, PopoverContent, PopoverTrigger } from "@shadcn/popover";
import { useFetchActiveGoalsWithProjects } from "@/queries/goals";
import { GoalProjects, Project } from "@shared/types";

interface ProjectPullDownProps {
  project?: string;
  onChange?: (projectId: string) => void;
}

export function ProjectPullDown({ project, onChange }: ProjectPullDownProps) {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const pullDownButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (project) {
      selectedId !== project && setSelectedId(project);
    }
  }, [project, selectedId]);

  const { data: goals } = useFetchActiveGoalsWithProjects();

  const pullDownButtonWidth = pullDownButtonRef.current ? pullDownButtonRef.current.offsetWidth : "auto";

  const allProjects = useMemo(() => {
    return goals?.flatMap((goal) => goal.projects || []) || [];
  }, [goals]);

  const selectedProject = useMemo(() => {
    return allProjects.find((p) => p.id === selectedId);
  }, [allProjects, selectedId]);

  function handleProjectSelect(projectId: string) {
    setSelectedId(projectId);
    setOpen(false);
    onChange && onChange(projectId);
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          ref={pullDownButtonRef}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[100%] justify-between"
        >
          {selectedProject?.name ?? "Select initiative..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent style={{ width: pullDownButtonWidth ? `${pullDownButtonWidth}px` : "auto" }} className="p-0">
        <Command>
          <CommandInput placeholder="Search initiative..." />
          <CommandEmpty>No initiative found.</CommandEmpty>
          {goals &&
            goals.map((goal: GoalProjects) => (
              <CommandGroup key={goal.id} heading={goal.name}>
                {goal.projects &&
                  goal.projects.length > 0 &&
                  goal.projects.map((project: Project) => (
                    <CommandItem key={project.id} value={project.id} onSelect={() => handleProjectSelect(project.id)}>
                      <Check className={cn("mr-2 h-4 w-4", selectedId === project.id ? "opacity-100" : "opacity-0")} />
                      {project.name}
                    </CommandItem>
                  ))}
              </CommandGroup>
            ))}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
