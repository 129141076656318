import { useState, useRef } from "react";
import { SyntheticEvent } from "react";
import { KnockProvider, KnockFeedProvider, NotificationFeedPopover, NotificationIconButton } from "@knocklabs/react";
import { useAuthContext } from "@/providers/AuthProvider";
import "@knocklabs/react/dist/index.css";

export function Notification() {
  const { user } = useAuthContext();
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef<HTMLButtonElement>(null);

  if (!user) return null;
  if (!process.env.KNOCK_PUBLIC_API_KEY || !process.env.KNOCK_FEED_ID)
    throw new Error("KNOCK_PUBLIC_API_KEY and KNOCK_FEED_ID must be set");

  const toggleVisibility = (e: SyntheticEvent | Event) => {
    e.preventDefault();
    e.stopPropagation();
    setIsVisible((prev) => !prev);
  };

  return (
    <KnockProvider apiKey={process.env.KNOCK_PUBLIC_API_KEY} userId={user.uid}>
      <KnockFeedProvider feedId={process.env.KNOCK_FEED_ID}>
        <>
          <div className="bg-white rounded-full">
            <NotificationIconButton 
              ref={notifButtonRef} 
              onClick={toggleVisibility} 
            />
          </div>
          <NotificationFeedPopover
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={toggleVisibility}
          />
        </>
      </KnockFeedProvider>
    </KnockProvider>
  );
}