/**
 * v0 by Vercel.
 * @see https://v0.dev/t/YFKOR6cdZTL
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

type Props = {
  // Color variant
  variant?: "default" | "primary";
};

const constructVariant = ({ variant = "default" }: Pick<Props, "variant">) => {
  switch (variant) {
    case "default":
      return "border-gray-300 dark:border-gray-600 border-t-gray-900 dark:border-t-gray-50";
    case "primary":
      return "border-primary/20 border-t-primary";
  }
};

export default function LoadingIndicator({ variant }: Props) {
  return (
    <div className="flex items-center justify-center h-full">
      <div className={`animate-spin rounded-full h-12 w-12 border-4 ${constructVariant({ variant })}`} />
    </div>
  );
}
