import { useFetchGoals } from "@/queries/goals";
import { AddGoalButtonDialog } from "@/web/pages/AddDialogs/AddGoalButtonDialog";
import { AddTimeBlockDialog } from "@components/AddTimeBlockDialog";
import { Button } from "@shadcn/button";
import { Plus } from "lucide-react";
import { useState } from "react";
import TimeblockSidebar from "../Sidebar/TimeblockSidebar";
import { GoalItem } from "./GoalItem";
import { NorthStarEmptyState } from "./NorthStarsEmptyState";

export const Northstar: React.FC = () => {
  const [addGoalButton, setAddGoalButton] = useState(false);
  const { isPending, data } = useFetchGoals();

  if (isPending) {
    return <div className="mx-auto max-w-7xl px-2 py-8 sm:px-6 lg:px-8 flex flex-col md:flex-row">Loading...</div>;
  }

  return (
    <>
      <div className="mx-auto max-w-7xl px-2 py-8 sm:px-6 lg:px-8 flex flex-col md:flex-row">
        <div id="northstars-list" className="flex-grow">
          {/* <h1 className="font-semibold leading-7 text-gray-900 text-2xl">Your Northstars</h1> */}
          <div className="border-b border-gray-200 pb-5 flex items-center justify-between">
            <h3 className="text-2xl font-semibold leading-8 text-gray-900">Your Northstars</h3>
            <div className="mt-3 sm:ml-4 sm:mt-0">
              {/* FIXME: not necessary to have open and setOpen. Need to clean up this old code */}
              <AddGoalButtonDialog open={addGoalButton} setOpen={setAddGoalButton}>
                <Button>
                  <Plus size={16} className="mr-1" /> Northstar
                </Button>
              </AddGoalButtonDialog>
            </div>
          </div>
          <p className="pt-4 text-gray-600">
            For best results, set maximum of 5 clear, focused northstars. Having a few well-defined objectives is more
            effective than numerous goals. Concentrate your efforts on these key northstars to make meaningful progress
            and achieve success.
          </p>
          <div className="pt-8 grid grid-cols-1 gap-4">
            {data && data.map((goal) => <GoalItem key={goal.id} goal={goal} />)}
            {data && data.length === 0 && <NorthStarEmptyState />}
          </div>
        </div>
        <div className="mt-8 md:mt-0 md:ml-8 min-w-[280px]">
          <div className="border-b border-gray-200 pb-5 flex items-center justify-between">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Your Focus Blocks</h3>
            <div className="mt-3 sm:ml-4 sm:mt-0">
              <AddTimeBlockDialog>
                <Button>
                  <Plus size={15} className="mr-1" />
                  Block
                </Button>
              </AddTimeBlockDialog>
            </div>
          </div>
          <p className="pt-4 text-gray-600">Dedicate time to your top priority by scheduling a focus session</p>
          <TimeblockSidebar />
        </div>
      </div>
    </>
  );
};
