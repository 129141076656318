// import JsPDF from "jspdf";
import { useParams } from "react-router-dom";
import { Button } from "@shadcn/button";
import { Toaster } from "@shadcn/toaster";
import PopoverAddToGoal from "./PopoverAddToGoal";
import TemplateView from "./TemplateView";
import { useAuthContext } from "@/providers/AuthProvider";

interface RouteParams {
  shareHash: string;
  [key: string]: string | undefined;
}

// const generatePDF = () => {
//   const report = new JsPDF("portrait", "pt", "a4");
//   const element = document.querySelector("#template-pdf") as HTMLElement;
//   report.html(element).then(() => {
//     report.save("template.pdf");
//   });
// };

const PublicProjectView: React.FC = () => {
  const { user: authUser } = useAuthContext();

  const isLogged = authUser != null;

  const { shareHash } = useParams<RouteParams>();
  if (!shareHash) {
    return <div>404</div>;
  }
  return (
    <div className="min-h-[100vh] w-full p-10 bg-gray-100">
      <div className="flex items-start justify-between mb-12">
        {/* <a href="#" className="text-gray-500 text-sm block mb-6">&#8592; Back to Library</a> */}
        <div></div>
        <div className="flex">
          {isLogged && (
            <PopoverAddToGoal shareHash={shareHash}>
              <Button>Copy to a Northstar</Button>
            </PopoverAddToGoal>
          )}
          {/* <Button onClick={generatePDF}>Download PDF</Button> */}
        </div>
      </div>
      <div className="flex justify-center">
        <TemplateView shareHash={shareHash} />
      </div>
      <Toaster />
    </div>
  );
};

export default PublicProjectView;
