import { useLocation } from 'react-router-dom';

type NavigationItem = {
  name: string;
  href: string;
  current: boolean;
  onClick?: () => void; // Optional onClick function
};

export const useUpdateNavigationBar = (navigation: NavigationItem[]) => {
    const location = useLocation();
  
    return navigation.map((item) => {
      // Extend the special case condition to include /projects
      const isSpecialCase = (
        (location.pathname.startsWith('/northstars') || location.pathname.startsWith('/goals') || location.pathname.startsWith('/projects')) &&
        (item.href === '/northstars' || item.href === '/goals' || item.href === '/projects')
      );

      const isRootPath = item.href === '/' && location.pathname === '/';
  
      return {
        ...item,
        // Use the special case condition, the root path condition, or the general condition for highlighting
        current: isSpecialCase || isRootPath || (item.href !== '/' && location.pathname.startsWith(item.href)),
      };
    });
  };