// Import the functions you need from the SDKs you need
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth";
import {
  Firestore,
  connectFirestoreEmulator,
  getFirestore,
} from "firebase/firestore";
import {
  Functions,
  connectFunctionsEmulator,
  getFunctions,
} from "firebase/functions";
import { Messaging, getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

let firebaseConfig: FirebaseOptions;

export function initializeFirebaseConfig(): typeof firebaseConfig {
    if (firebaseConfig) return firebaseConfig;

    if (!process.env.REACT_APP_FIREBASE_PROJECT_ID) {
      throw new Error("Firebase Project ID is not set. Likely missing .env file.");
    }

    firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    return firebaseConfig;
}

let app: FirebaseApp | undefined;
export function initializeFirebaseApp(): FirebaseApp {
  if (app) return app; // Avoid reinitialization

  const config = initializeFirebaseConfig();

  app = initializeApp(config);
  return app;
}

let db: Firestore | undefined;
export function initializeFirestore(): Firestore {
  if (!app) initializeFirebaseApp();
  if (db) return db; // Avoid reinitialization
  if (!app) {
    throw new Error("Firebase app is not initialized");
  }
  db = getFirestore(app);
  if (process.env.NODE_ENV === "development" && !process.env.CODESPACE_NAME) {
    connectFirestoreEmulator(db, "localhost", 8080);
  }
  return db;
}

let auth: Auth | undefined;
export function initializeAuth(): Auth {
  if (!app) initializeFirebaseApp();
  if (auth) return auth; // Avoid reinitialization
  auth = getAuth(app);
  if (process.env.NODE_ENV === "development" && !process.env.CODESPACE_NAME) {
    connectAuthEmulator(auth, "http://localhost:9099");
  }
  return auth;
}

let functions: Functions | undefined;
export function initializeFunctions(): Functions {
  if (!app) initializeFirebaseApp();
  if (functions) return functions; // Avoid reinitialization
  functions = getFunctions(app);
  if (process.env.NODE_ENV === "development" && !process.env.CODESPACE_NAME) {
    connectFunctionsEmulator(functions, "localhost", 5001);
  }
  return functions;
}

let messaging: Messaging | undefined;
export function initializeMessaging(): Messaging {
  if (!app) initializeFirebaseApp();
  if (messaging) return messaging; // Avoid reinitialization
  messaging = getMessaging(app);
  return messaging;
}