import { Button } from "@shadcn/button";
import { GraduationCap } from "lucide-react";
import { LearnOverlay } from "@components/LearnOverlay";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export function Learn() {
  const [showOverlay, setShowOverlay] = useState(false);
  const location = useLocation();
  const rawPath = location.pathname.split("/learn/")[1] || "";
  const path = encodeURI(rawPath);

  useEffect(() => {
    setShowOverlay(true);
  }, []);

  // Prevent body scrolling when iframe is open
  useEffect(() => {
    const body = document.body;
    if (showOverlay) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }

    // Cleanup function to ensure the class is removed when the component unmounts
    return () => {
      body.classList.remove("overflow-hidden");
    };
  }, [showOverlay]);

  return (
    <div className="container mx-auto max-w-[700px] mt-8">
      <h1 className="text-3xl font-bold">Welcome to the "Learn" Section: Empowering Your Productivity Journey</h1>

      <p className="mt-4">
        Unlock your full potential with our "Learn" section, a dedicated space designed for those who strive to excel in
        both personal and professional life. Here, we offer a curated selection of online courses and a vibrant
        community focused on productivity and self-improvement. Whether you're looking to master time management,
        enhance your focus, or cultivate resilience, our resources are tailored to meet your needs.
      </p>
      <Button className="mt-4" onClick={() => setShowOverlay(true)}>
        <GraduationCap size={16} className="mr-1" />
        Reopen Learn Platform
      </Button>
      <LearnOverlay show={showOverlay} path={path} onClose={() => setShowOverlay(false)} />
    </div>
  );
}
