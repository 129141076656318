import { Button } from "@shadcn/button";
import { Popover, PopoverContent, PopoverTrigger } from "@shadcn/popover";

import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@shadcn/select";
import { useToast } from "@shadcn/use-toast";
import { useFetchGoals } from "../../../queries/goals";
import { useAddProjectMutation } from "../../../queries/projects";
import { useFetchPublicProjectByShareHash } from "../../../queries/projects";

SelectGoal.propType = {
  onGoalSelected: PropTypes.func.isRequired,
};

function SelectGoal({ onGoalSelected }: { onGoalSelected: (goalId: string) => void }) {
  const { data: goals } = useFetchGoals();

  if (!goals) return null;

  const handleSelect = (goalId: string) => {
    console.log("Selected goal:", goalId);
    onGoalSelected(goalId);
  };

  return (
    <Select onValueChange={handleSelect}>
      <SelectTrigger className="w-[180px] col-span-2 h-8 overflow-hidden whitespace-nowrap overflow-ellipsis">
        <SelectValue placeholder="Select a Northstar" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {goals.map((goal) => (
            <SelectItem key={goal.id} value={goal.id}>
              {goal.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

type PopoverAddToGoalProps = {
  children?: React.ReactNode;
  shareHash: string;
};

function PopoverAddToGoal({ children, shareHash }: PopoverAddToGoalProps) {
  const addProject = useAddProjectMutation();
  const { data: sharedProject, isPending } = useFetchPublicProjectByShareHash(shareHash);

  const [selectedGoalId, setSelectedGoalId] = useState("");
  const { toast } = useToast();
  const navigate = useNavigate();

  if (isPending) return null;

  const handleGoalSelected = (goalId: string) => {
    setSelectedGoalId(goalId);
  };

  const handleCopy = async () => {
    try {
      if (sharedProject && selectedGoalId) {
        // Create a new project using the fetched project's data
        addProject.mutate({
          name: sharedProject.name,
          details: sharedProject.details,
          goalId: selectedGoalId,
        });
      }
      toast({
        title: "Action Recipe is copied to Northstar.",
        description: "Redirecting to your Northstar in 3 seconds...",
      });
      setTimeout(() => {
        navigate(`/goals/${selectedGoalId}`);
      }, 3000);
    } catch (error) {
      console.error("Error copying the project:", error);
      toast({
        title: "There was an error copying the Action Recipe",
        description: "Please try again later or email support@lifehack.org for support.",
      });
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="grid gap-4">
          <div className="grid gap-2">
            <div className="grid grid-cols-3 items-center gap-4">
              <SelectGoal onGoalSelected={handleGoalSelected} />

              <Button className="h-8" onClick={handleCopy}>
                Copy
              </Button>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default PopoverAddToGoal;
