import { createHeadlessEditor } from "@lexical/headless";
import { $generateHtmlFromNodes } from "@lexical/html";
import { LexicalEditor } from "lexical";
import { useEffect, useState } from "react";

import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";

export function useHeadlessToHtml(content: string | undefined) {
    const [editor, setEditor] = useState<LexicalEditor | null>(null);

    useEffect(() => {
        if (content) {
          // const parsedEditorState: EditorState = JSON.parse(project.details);
          // setEditor(createHeadlessEditor({ editorState: parsedEditorState }));
          const editor = createHeadlessEditor({
            nodes: [
              HeadingNode,
              ListNode,
              ListItemNode,
              QuoteNode,
              CodeNode,
              CodeHighlightNode,
              TableNode,
              TableCellNode,
              TableRowNode,
              AutoLinkNode,
              LinkNode,
            ],
          });
          editor.setEditorState(editor.parseEditorState(content));  
          setEditor(editor);
        }
      }, [content]);

      if (content && editor) {
        let html = "";
        if (content) {
          // sanitizedHTML = createSantizedHtml(project.details);
          const editorState = editor.getEditorState();
          editorState.read(() => {
            html = $generateHtmlFromNodes(editor, null) ?? "<p>No content</p>";
          });
        } else {
            html = "<p>No content</p>";
        }
        return html;
    }

    return null;
}