import { CalendarDays, ChevronsUpDown, Trash } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@shadcn/collapsible";
import { useDeleteTimeblockMutation, useFetchTimeblocks } from "../../../queries/timeblocks";
import { Timeblock } from "@shared/types";

interface TimeblockListProps {
  projectId: string; // Add the projectId prop
}

const TimeblockList: React.FC<TimeblockListProps> = ({ projectId }) => {
  const { isPending, data: allTimeblocks } = useFetchTimeblocks();
  const deleteTimeblock = useDeleteTimeblockMutation();

  const [hoveredTimeblockId, setHoveredTimeblockId] = useState<string | null>(null);

  const timeblocks = allTimeblocks?.filter((timeblock) => timeblock.projectId === projectId);

  const handleTimeblockHover = (timeblockId: string) => {
    setHoveredTimeblockId(timeblockId);
  };

  const handleTimeblockLeave = () => {
    setHoveredTimeblockId(null);
  };

  const formatTime = (date: Date, includeDate = true) => {
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    if (includeDate) {
      const [weekday, month, day] = date.toDateString().split(" ");
      return `${weekday} ${month} ${day} ${hours}:${minutes}`;
    }
    return `${hours}:${minutes}`;
  };

  const handleDeleteTimeblock = (timeblockId: string) => {
    deleteTimeblock.mutate(timeblockId);
  };

  const formatDuration = (duration: number) => {
    return `${duration} hour${duration > 1 ? "s" : ""}`;
  };

  // This is to re-render the component every 1 minute.
  useEffect(() => {
    const timer = setInterval(() => {
      // This is the function that gets executed every 1 minute.
      // We are forcing a re-render of the component by changing the state.
      setHoveredTimeblockId((prevHoveredTimeblockId) => prevHoveredTimeblockId);
    }, 60000); // 60000 ms = 1 minute

    // This is the clean-up function.
    // It gets executed when the component is unmounted.
    // It's important to clear the interval to prevent memory leaks.
    return () => clearInterval(timer);
  }, []);

  if (isPending) return <div>Loading...</div>;
  if (!timeblocks) return <div>Error</div>;

  const upcomingTimeblocks = timeblocks
    .filter((timeblock) => {
      const startTime = new Date(timeblock.startTime);
      const endTime = new Date(startTime.getTime() + timeblock.duration * 60 * 60 * 1000);
      return endTime > new Date(); // If endTime is in the future, it's an upcoming timeblock
    })
    .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()); // Sort in ascending order

  const completedTimeblocks = timeblocks
    .filter((timeblock) => {
      const startTime = new Date(timeblock.startTime);
      const endTime = new Date(startTime.getTime() + timeblock.duration * 60 * 60 * 1000);
      return endTime <= new Date(); // If endTime is in the past, it's a completed timeblock
    })
    .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()); // Sort in ascending order

  const renderTimeblock = (timeblock: Timeblock) => {
    const startTime = new Date(timeblock.startTime);
    const endTime = new Date(startTime.getTime() + timeblock.duration * 60 * 60 * 1000);
    return (
      <CollapsibleContent
        key={timeblock.id}
        onMouseEnter={() => handleTimeblockHover(timeblock.id)}
        onMouseLeave={handleTimeblockLeave}
      >
        <div className="flex justify-between items-center rounded-md border px-4 py-3 mt-2 font-mono text-xs">
          <div>{`${formatTime(startTime)}-${formatTime(endTime, false)}`}</div>
          {hoveredTimeblockId === timeblock.id && (
            <Trash size={16} className="inline cursor-pointer" onClick={() => handleDeleteTimeblock(timeblock.id)} />
          )}
        </div>
      </CollapsibleContent>
    );
  };

  // // If there are no timeblocks at all, do not render the component
  // if (upcomingTimeblocks.length === 0 && completedTimeblocks.length === 0) {
  //   return null;
  // }

  return (
    <Collapsible className="w-full">
      <CollapsibleTrigger className="w-full">
        <div className="rounded-md border px-4 py-3 text-xs flex justify-between items-center font-mono">
          <div>
            <CalendarDays size="16" className="inline mr-3 align-bottom" />
            {upcomingTimeblocks.length > 0 ? (
              <React.Fragment>
                {new Date().getTime() >= new Date(upcomingTimeblocks[0].startTime).getTime() &&
                new Date().getTime() <=
                  new Date(upcomingTimeblocks[0].startTime).getTime() + upcomingTimeblocks[0].duration * 60 * 60 * 1000
                  ? "Now: "
                  : "Next: "}
                {formatTime(new Date(upcomingTimeblocks[0].startTime)) +
                  " (" +
                  formatDuration(upcomingTimeblocks[0].duration) +
                  ")"}
              </React.Fragment>
            ) : (
              "No Upcoming Focus Blocks"
            )}
          </div>
          {upcomingTimeblocks.length > 1 && <ChevronsUpDown className="h-4 w-4 inline" />}
        </div>
      </CollapsibleTrigger>

      {/* Show the "Upcoming" header only if there is more than one upcoming timeblock */}
      {upcomingTimeblocks.length > 1 && (
        <CollapsibleContent>
          <div className="text-xs text-gray-600 font-bold uppercase ml-0 md:ml-4 mt-4">Upcoming</div>
        </CollapsibleContent>
      )}
      {/* Skip the first one in upcomingTimeblocks */}
      {upcomingTimeblocks.slice(1).map((timeblock) => renderTimeblock(timeblock))}

      {/* Show the "Completed" header only if there are completed timeblocks */}
      {completedTimeblocks.length > 0 && (
        <CollapsibleContent>
          <div className="text-xs text-gray-600 font-bold uppercase ml-0 md:ml-4 mt-4">Completed</div>
        </CollapsibleContent>
      )}
      {completedTimeblocks.map((timeblock) => renderTimeblock(timeblock))}
    </Collapsible>
  );
};

export default TimeblockList;
