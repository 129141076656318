import { Option } from "@shared/types";

type Props = {
  option: Option;
  isSelected: boolean;
  onChange: (value: string) => void;
  index: number;
  name: string;
  required?: boolean;
};

export default function RadioOption({ option: { value }, required, name, isSelected, onChange, index }: Props) {
  const letters = "ABCDEFGHIJ";
  return (
    <label
      htmlFor={name + value}
      className={`relative bg-white h-10 px-2.5 pr-5 cursor-pointer flex items-center gap-2.5 rounded border ${
        isSelected ? "border-primary text-primary" : ""
      }`}
    >
      <div
        className={`h-5 w-5 min-w-5 rounded text-sm leading-none text-white grid place-content-center ${
          isSelected ? "bg-primary" : "bg-gray-400"
        }`}
      >
        {letters.split("")[index]}
      </div>
      <span className="select-none min-w-max">{value}</span>
      <input
        className="absolute inset-0 appearance-none opacity-0 w-full h-full cursor-pointer"
        type="radio"
        checked={isSelected}
        onChange={(e) => onChange(e.target.value)}
        name={name}
        value={value}
        required={required}
        id={name + value}
      />
    </label>
  );
}
