import { useState } from "react";
import { CardContent } from "@shadcn/card";
import { Button } from "@shadcn/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@shadcn/select";
import { useUpdateUserMutation } from "@/queries/user";
import { Goal } from "@shared/types";

interface EditBigNorthstarProps {
  goals: Goal[];
  currentGoalId: string;
  onSave: () => void;
}

export function EditBigNorthstar({ goals, currentGoalId, onSave }: EditBigNorthstarProps) {
  const [selectedGoalId, setSelectedGoalId] = useState(currentGoalId);
  const updateUserMutation = useUpdateUserMutation();

  const handleSaveClick = () => {
    updateUserMutation.mutate(
      { bigGoalId: selectedGoalId },
      {
        onSuccess: () => {
          onSave();
        },
        onError: (error) => {
          console.error("Failed to update bigGoalId:", error);
        },
      }
    );
  };

  return (
    <CardContent>
      <p className="mb-6 text-slate-600">
        Select a Northstar that you want to focus on. Your Big North Star isn't just a goal—it's your life's guiding
        light. It focuses your energy, gives meaning to your actions, and unlocks hidden potential.
      </p>
      <div className="space-y-4">
        <Select value={selectedGoalId} onValueChange={setSelectedGoalId}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a Northstar" />
          </SelectTrigger>
          <SelectContent>
            {goals.map((g) => (
              <SelectItem key={g.id} value={g.id}>
                {g.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="flex justify-end">
          <Button onClick={handleSaveClick}>Save</Button>
        </div>
      </div>
    </CardContent>
  );
}
