import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addDoc, collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { initializeFirestore, initializeAuth } from "../utils/firebaseConfig";
import { Timeblock } from "@shared/types";
import { captureException } from "../utils/logging";

export { useFetchTimeblocks, useFetchTimeblocksByProjectId, useAddTimeblockMutation, useDeleteTimeblockMutation };

const useFetchTimeblocks = () => {
  return useQuery<Timeblock[], Error>({
    queryKey: ["timeblocks"],
    queryFn: async () => fetchTimeblocks(),
  });
};

const useFetchTimeblocksByProjectId = (projectId: string) => {
  return useQuery<Timeblock[], Error>({
    queryKey: ["timeblocks", { projectId: projectId }],
    queryFn: async () => fetchTimeblocksByProjectId(projectId),
  });
};

const useAddTimeblockMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (timeblock: Partial<Timeblock>) => {
      await addTimeblock(timeblock);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["timeblocks"] });
    },
  });
};

const useDeleteTimeblockMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (timeblockId: string) => {
      await deleteTimeblock(timeblockId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["timeblocks"] });
    },
  });
};

async function addTimeblock(timeblock: Partial<Timeblock>) {
  try {
    if (!timeblock.startTime) throw new Error("startTime is required");
    if (!timeblock.duration) throw new Error("duration is required");

    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const timeblockCollectionRef = collection(db, "timeblocks");
    const timeblockRef = await addDoc(timeblockCollectionRef, {
      type: timeblock.type ?? "project",
      adhocName: timeblock.adhocName ?? null,
      projectId: timeblock.projectId ?? null,
      startTime: timeblock.startTime,
      duration: timeblock.duration,
      userId: uid,
    });
    return { id: timeblockRef.id };
  } catch (error) {
    captureException("error adding timeblock", error as Error);
    throw error;
  }
}

async function deleteTimeblock(timeblockId: string) {
  try {
    const db = initializeFirestore();
    const timeblockRef = doc(db, "timeblocks", timeblockId);
    await deleteDoc(timeblockRef);
  } catch (error) {
    // Handle error
    console.log("error deleting timeblock", error);
    throw error;
  }
}

async function fetchTimeblocksByProjectId(projectId: string): Promise<Timeblock[]> {
  try {
    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const timeblocksCollectionRef = collection(db, "timeblocks");
    const querySnapshot = await getDocs(
      query(timeblocksCollectionRef, where("userId", "==", uid), where("projectId", "==", projectId))
    );
    const timeblocks: Timeblock[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      timeblocks.push({
        id: doc.id,
        type: data.type ?? "project",
        startTime: data.startTime.toDate(),
        duration: data.duration,
        adhocName: data.adhocName ?? null,
        projectId: data.projectId ?? null,
        userId: uid,
      });
    });

    return timeblocks;
  } catch (error) {
    captureException("error loading timeblocks", error as Error);
    throw error;
  }
}

async function fetchTimeblocks(): Promise<Timeblock[]> {
  try {
    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const timeblocksCollectionRef = collection(db, "timeblocks");
    const querySnapshot = await getDocs(query(timeblocksCollectionRef, where("userId", "==", uid)));
    const timeblocks: Timeblock[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      timeblocks.push({
        id: doc.id,
        type: data.type ?? "project",
        startTime: data.startTime.toDate(),
        duration: data.duration,
        adhocName: data.adhocName ?? null,
        projectId: data.projectId ?? null,
        userId: uid,
      });
    });

    return timeblocks;
  } catch (error) {
    captureException("error loading timeblocks", error as Error);
    throw error;
  }
}
