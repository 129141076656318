interface EmptyStateProps {
  image?: string;
  title?: string;
  description?: string;
}
import default_image from "../../images/empty/add-initiatives.png";

export const EmptyState = ({ image = default_image, title = "No data found", description }: EmptyStateProps) => {
  return (
    <div className="flex items-start justify-center h-full">
      <div className="w-full text-center">
        <img src={image} alt="{title}" className="mx-auto mt-4 mb-4 w-64" />
        <div className="font-bold text-xl">{title}</div>
        <div className="text-gray-500 mt-2">{description}</div>
      </div>
    </div>
  );
};
