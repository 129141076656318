import { useEffect, useState } from "react";
import { $getRoot } from "lexical";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin"; // New import here
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode, $isListNode, $isListItemNode, $createListItemNode } from "@lexical/list";
import { TRANSFORMERS } from "@lexical/markdown";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { LinkPlugin } from "./plugins/LinkPlugin";

import ComponentPickerMenuPlugin from "./plugins/ComponentPickerMenuPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import { ParagraphPlaceholderPlugin } from "./plugins/ParagraphPlaceholderPlugin";

import { useDebouncedCallback } from 'use-debounce';
// import "./style.css";
import { Skeleton } from "@shadcn/skeleton";
import "./index.css";
import theme from "./theme/ProjectEditorTheme";
import "./theme/ProjectEditorTheme.css";

import "./ui/Checkbox.css";
// import theme from "./theme";

import { INSERT_CHECK_LIST_COMMAND } from "@lexical/list";
import { Button } from "@shadcn/button"; // Assuming you're using shadcn UI components
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@shadcn/tooltip";
import { $createParagraphNode, $getSelection, $insertNodes, $isRangeSelection, Klass, LexicalNode } from "lexical";
import { PlusIcon, Sparkles } from "lucide-react";
import { useFetchProjectByProjectId, useUpdateProjectMutation } from "../../../queries/projects";
import { AiActionItemsPlugin, INSERT_AI_ACTION_ITEMS_COMMAND } from "./plugins/AiActionItemsPlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";

// function useOnChange() {
//   const { projectID } = useParams();
//   const dispatch = useDispatch();

//   function onChange(editorState: any) {
//     editorState.read(() => {
//       const root = $getRoot();
//       const selection = $getSelection();

//       console.log(root, selection, projectID);

//       if (projectID) {
//         const savedState = editorState.toJSON();
//         dispatch(
//           updateProjectToFirestore({
//             projectId: projectID,
//             fieldsToUpdate: { details: savedState },
//           })
//         );
//       }
//     });
//   }

//   return onChange;
// }

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
// function MyCustomAutoFocusPlugin() {
//   const [editor] = useLexicalComposerContext();

//   useEffect(() => {
//     // Focus the editor when the effect fires!
//     editor.focus();
//   }, [editor]);

//   return null;
// }

type StoragePluginProps = {
  projectId: string;
};

export function StoragePlugin({ projectId }: StoragePluginProps) {
  const [editor] = useLexicalComposerContext();
  const updateProjectMutation = useUpdateProjectMutation();

  const debouncedSaveContent = useDebouncedCallback(
    (content: string, completedTasks: number, incompleteTasks: number) => {
      updateProjectMutation.mutate({
        id: projectId,
        details: content,
        numCompletedTasks: completedTasks,
        numIncompleteTasks: incompleteTasks,
      });
      console.log("Saved content to firestore: ", content);
      console.log("Completed tasks: ", completedTasks, "Incomplete tasks: ", incompleteTasks);
    },
    500 // 500ms delay
  );

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState, dirtyElements, dirtyLeaves }) => {
      // Don't update if nothing changed
      if (dirtyElements.size === 0 && dirtyLeaves.size === 0) return;

      editorState.read(() => {
        let completedTasks = 0;
        let incompleteTasks = 0;

        const root = $getRoot();
        root.getChildren().forEach((node) => {
          if ($isListNode(node) && node.getListType() === "check") {
            node.getChildren().forEach((listItem) => {
              if ($isListItemNode(listItem)) {
                if (listItem.getChecked()) {
                  completedTasks++;
                } else {
                  incompleteTasks++;
                }
              }
            });
          }
        });

        const serializedState = JSON.stringify(editorState);
        debouncedSaveContent(serializedState, completedTasks, incompleteTasks);
      });
    });
  }, [debouncedSaveContent, editor]);

  return null;
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
  console.error(error);
}

type EditorProps = {
  projectId: string;
};

export function Editor({ projectId }: EditorProps) {
  const { isPending, data: project } = useFetchProjectByProjectId(projectId);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  // const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);
  const [isSmallWidthViewport] = useState<boolean>(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  // useEffect(() => {
  //   const fetchProjectContent = async () => {
  //     await dispatch(fetchActiveProjects());
  //     await dispatch(fetchCompletedProjects());

  //     setLoadedState(true);
  //   };

  //   fetchProjectContent();
  // }, [dispatch, projectId]);

  // if (!loadedState) {
  //   return <div>Loading...</div>; // or some other loading state
  // }

  // const project = useSelector((state: RootState) =>
  //   selectProjectById(state, projectId)
  // );

  if (isPending || !project) {
    return (
      <div className="w-full h-full flex justify-center items-center p-6">
        <div>
          <Skeleton className="h-4 w-[400px] mb-3" />
          <Skeleton className="h-4 w-[240px] mb-3" />
          <Skeleton className="h-4 w-[500px] mb-2" />
          <Skeleton className="h-4 w-[520px] mb-2" />
          <Skeleton className="h-4 w-[550px] mb-2" />
          <Skeleton className="h-4 w-[510px] mb-2" />
          <Skeleton className="h-4 w-[290px]" />
        </div>
      </div>
    );
  }
  const initialEditorState =
    project && project.details && JSON.parse(project.details).root.children.length > 0 ? project.details : undefined;

  const initialConfig = {
    namespace: "MyEditor",
    theme,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ] as Klass<LexicalNode>[],
    editorState: initialEditorState,
    onError,
  };

  return (
    <div className="editor">
      <LexicalComposer initialConfig={initialConfig}>
        {/* <ToolbarPlugin /> */}
        <div className="editor-container mb-10" ref={onRef}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable className="editor-input focus:outline-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0" />
            }
            placeholder={
              <div className="editor-placeholder">
                Start forming your project plan or press &quot;/&quot; for commands
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          {/* <AutoFocusPlugin /> */}
          <ListPlugin />
          <CheckListPlugin />
          <TabIndentationPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          {floatingAnchorElem && !isSmallWidthViewport && (
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
          )}
          <ListMaxIndentLevelPlugin maxDepth={4} />
          <StoragePlugin projectId={projectId} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <ComponentPickerMenuPlugin />
          <ParagraphPlaceholderPlugin placeholder={`Press "/" for commands`} hideOnEmptyEditor />
          <FloatingTextFormatToolbarPlugin />
          <AiActionItemsPlugin projectId={projectId} />
          <StickyToolbar />
        </div>
      </LexicalComposer>
    </div>
  );
}

function StickyToolbar() {
  const [editor] = useLexicalComposerContext();

  const handleAddTask = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $insertNodes([$createParagraphNode()]);
        const newListItem = $createListItemNode();
        newListItem.append($createParagraphNode());
        $insertNodes([newListItem]);
      }
    });
    editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
  };

  const handleRunAIActionItems = () => {
    editor.dispatchCommand(INSERT_AI_ACTION_ITEMS_COMMAND, undefined);
  };

  return (
    <div className="fixed bottom-4 left-1/2 -translate-x-1/2 flex items-center gap-4 rounded-full bg-background p-2 shadow-lg">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon" className="rounded-full" onClick={handleAddTask}>
              <PlusIcon className="h-6 w-6" />
              <span className="sr-only">Add Action</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Add Action</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon" className="rounded-full" onClick={handleRunAIActionItems}>
              <Sparkles className="h-4 w-4" />
              <span className="sr-only">AI Copilot: Generate Actions</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>AI Copilot: Generate Actions</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
