/**
 * v0 by Vercel.
 * @see https://v0.dev/t/B5LdzpFRWhq
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { useState } from "react";
import { Textarea } from "@shadcn/textarea";
import { Button } from "@shadcn/button";
import { useSendFeedback } from "@/queries/user"; // Updated import
import { Smile, Frown, Meh, X } from "lucide-react";
import { z } from "zod";
import { useToast } from "@shadcn/use-toast";

interface UserFeedbackPopupProps {
  onClose: () => void;
}

const feedbackSchema = z.object({
  feedback: z.string().min(1, "Feedback is required"),
  rating: z.number().min(1, "Rating is required").max(3, "Invalid rating"),
  url: z.string().url(),
});

export default function UserFeedbackPopup({ onClose }: UserFeedbackPopupProps) {
  const [feedbackData, setFeedbackData] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<{ feedback?: string; rating?: string }>({});
  const { toast } = useToast();

  const mutation = useSendFeedback(); // Updated to use generalized hook

  const handleSubmit = () => {
    const currentUrl = window.location.href;
    feedbackData.url = currentUrl; // Ensure URL is part of feedback data

    const result = feedbackSchema.safeParse(feedbackData);

    if (!result.success) {
      const formErrors = result.error.format();
      setErrors({
        feedback: formErrors.feedback?._errors[0],
        rating: formErrors.rating?._errors[0],
      });
      return;
    }

    mutation.mutate(feedbackData, {
      onSuccess: () => {
        toast({
          title: "Feedback Submitted",
          description: "Thank you for your feedback!",
        });
        onClose();
      },
    });
  };

  const handleRatingClick = (value: number) => {
    setFeedbackData((prev) => ({ ...prev, rating: value }));
    setErrors((prev) => ({ ...prev, rating: undefined }));
  };

  return (
    <div
      key="1"
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div className="bg-white rounded-lg max-w-md w-full p-6 space-y-4" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Leave feedback</h2>
          <button aria-label="Close" onClick={onClose}>
            <X className="h-6 w-6" />
          </button>
        </div>
        <p>We'd love to hear what went well or how we can improve the product experience.</p>
        <Textarea
          className="border-gray-300"
          placeholder="What if..."
          value={feedbackData.feedback || ""}
          onChange={(e) => {
            setFeedbackData((prev) => ({ ...prev, feedback: e.target.value }));
            setErrors((prev) => ({ ...prev, feedback: undefined }));
          }}
        />
        {errors.feedback && <p className="text-red-500">{errors.feedback}</p>}
        <div className="flex space-x-2">
          {[1, 2, 3].map((value) => (
            <button
              key={value}
              className={`border rounded-lg p-2 ${feedbackData.rating === value ? 'bg-primary text-white' : ''}`}
              onClick={() => handleRatingClick(value)}
            >
              {value === 1 ? <Frown className="h-6 w-6" /> : value === 2 ? <Meh className="h-6 w-6" /> : <Smile className="h-6 w-6" />}
            </button>
          ))}
        </div>
        {errors.rating && <p className="text-red-500">{errors.rating}</p>}
        <Button className="w-full" onClick={handleSubmit}>Submit</Button>
      </div>
    </div>
  );
}
