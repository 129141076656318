import { initializeAuth } from "../utils/firebaseConfig";

export const aiApiUrl = process.env.REACT_APP_AI_API_URL;
export const internalApiUrl = process.env.REACT_APP_INTERNAL_API_URL;

export async function setupConfig() {
    const auth = initializeAuth();
    const token = await auth.currentUser?.getIdToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }