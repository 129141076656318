import { useState, useEffect } from "react";
import coachesJson from "./coaches.json";
import { AiCoachResponse } from "./AiCoachResponse";
import { AiTypeButton } from "./AiTypeButton";
import { PromptAnswer } from "@shared/types/prompt";
import { useFetchAllResponsesByPromptAnswerId } from "@/queries/promptAnswers";
import { PromptResponse } from "@shared/types/prompt";

interface Coach {
  name: string;
  description: string;
  prompt_id: string;
  whitelist_category?: string[];
  icon?: string;
}

const coaches: { [key: string]: Coach } = coachesJson;

export function AiSection({ promptAnswer }: { promptAnswer: PromptAnswer }) {
  const filteredCoaches = Object.keys(coaches).filter(
    (key) =>
      !coaches[key].whitelist_category ||
      (promptAnswer.category && coaches[key].whitelist_category?.includes(promptAnswer.category))
  );

  const [activeKey, setActiveKey] = useState(filteredCoaches[0]);
  const [coachStates, setCoachStates] = useState<{ [key: string]: string }>({});

  const setCoachState = (key: string, state: string) => {
    setCoachStates((prevStates) => ({
      ...prevStates,
      [key]: state,
    }));
  };

  const { data: responses } = useFetchAllResponsesByPromptAnswerId(promptAnswer.id);

  useEffect(() => {
    if (responses) {
      const sortedResponses = responses.sort((a, b) => {
        const dateA = a.updatedAt ? a.updatedAt.toDate().getTime() : 0;
        const dateB = b.updatedAt ? b.updatedAt.toDate().getTime() : 0;
        return dateB - dateA;
      });
      const initialStates = sortedResponses.reduce((acc, response: PromptResponse) => {
        if (!acc[response.responseTypeId]) {
          acc[response.responseTypeId] = response.answer;
        }
        return acc;
      }, {} as { [key: string]: string });
      setCoachStates(initialStates);
    }
  }, [responses]);

  return (
    <div className="mt-20 pb-32 min-h-[1280px]">
      <h1 className="text-3xl text-primary mb-2">Get Feedback</h1>
      <p>
        Get personalized insights, feedback and recommendations from your Lifehack AI Coach. We&apos;ll send you a
        personalized report based on your answers.
      </p>
      <div className="flex flex-col md:flex-row pt-10">
        <div className="w-full md:w-1/4 md:pr-10 mb-4 md:mb-0">
          {filteredCoaches.map((key) => (
            <AiTypeButton
              key={key}
              name={coaches[key].name}
              isActive={key === activeKey}
              icon={coaches[key].icon || ""}
              onClick={() => setActiveKey(key)}
            />
          ))}
        </div>
        <div className="flex-1">
          <AiCoachResponse
            activeKey={activeKey}
            coach={coaches[activeKey]}
            promptAnswer={promptAnswer}
            response={coachStates[activeKey]}
            setResponse={(state: string) => setCoachState(activeKey, state)}
          />
        </div>
      </div>
    </div>
  );
}
