import { useState } from "react";
import { CalendarDays } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@shadcn/popover";
import { Calendar } from "@shadcn/calendar";
import { OptionButton } from "./OptionButton";

type CalendarPopoverProps = {
  onDateChange: (date: Date) => void;
};

export const CalendarPopover = ({ onDateChange }: CalendarPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState<Date | undefined>(new Date());

  const formatDate = (date: Date | undefined): string => {
    if (!date) return "";

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });

    return `${day} ${month}`;
  };

  const handleDateChange = (date: Date | undefined) => {
    if (!date) return;
    setDate(date);
    setIsOpen(false);
    onDateChange(date);
  };

  return (
    <Popover open={isOpen} onOpenChange={(state) => setIsOpen(state)}>
      <PopoverTrigger>
        <OptionButton>
          <CalendarDays className="inline-block" />
          <span>{formatDate(date)}</span>
        </OptionButton>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar mode="single" selected={date} onSelect={handleDateChange} />
      </PopoverContent>
    </Popover>
  );
};
