type OptionButtonProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const OptionButton = ({ children }: OptionButtonProps) => {
  return (
    <div className="p-2 inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground">
      <div className="space-x-2">{children}</div>
    </div>
  );
};
