import { Alert, AlertDescription, AlertTitle } from "@shadcn/alert";
import { Button } from "@shadcn/button";
import { Check, Info, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useFetchAiGoalRecomendations } from "../../../queries/ai";
import { useAddGoalMutation } from "../../../queries/goals";
import WarningBox from "../../components/WarningBox";
import { AiWaitingIndicator } from "./AiWaitingIndicator";

interface GoalRecommendation {
  goal: string;
  description: string;
}

interface SuggestGoalResultsProps {
  userInput: string;
}

function SuggestGoalResults({ userInput }: SuggestGoalResultsProps) {
  const addGoalMutation = useAddGoalMutation();
  const [addedGoals, setAddedGoals] = useState<Record<string, boolean>>({}); // New state to keep track of added goals
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [goals, setGoals] = useState<GoalRecommendation[]>([]);

  const { data, error, isPending } = useFetchAiGoalRecomendations(userInput);

  useEffect(() => {
    console.log("data", data);
    if (data?.recommendations) {
      setGoals(data?.recommendations);
    }
  }, [data]);

  if (userInput == "") {
    return <div>empty</div>;
  }

  if (error) {
    setErrorMessage("Error loading goal recommendations. Please try again later.");
  }

  if (isPending) {
    return <AiWaitingIndicator />;
  }

  const addGoal = (goal: string, description: string) => {
    addGoalMutation.mutate({ name: goal, details: description });
    setAddedGoals((prev) => ({ ...prev, [goal]: true })); // Update addedGoals state after a goal is added
  };

  return (
    <>
      {goals.map((goal) => (
        <div
          key={goal.goal}
          className="flex justify-between items-center p-6 rounded-md border border-gray-200 mb-2 hover:shadow-lg transition-shadow"
        >
          <div className="pr-6">
            <h2 className="text-lg font-bold mb-1">{goal.goal}</h2>
            <p className="text-slate-500">{goal.description}</p>
          </div>
          {addedGoals[goal.goal] ? (
            <Button disabled>
              <Check size={16} className="inline mr-1" /> Added
            </Button>
          ) : (
            <Button onClick={() => addGoal(goal.goal, goal.description)}>
              <Plus size={16} className="inline mr-1" /> Add
            </Button>
          )}
        </div>
      ))}
      {errorMessage && <WarningBox errorMessage={errorMessage} />}
      {goals.length > 0 && (
        <Alert className="mb-2 bg-yellow-100 border-none text-slate-600">
          <Info className="h-4 w-4" />
          <AlertTitle className="font-bold">Struggling with these suggestions?</AlertTitle>
          <AlertDescription className="leading-snug">
            <p>
              Try reshaping your query with new keywords or a fresh perspective, then ask your A.I. Copilot for more
              ideas. Remember, you can do this repeatedly until you&rsquo;re ready to start - you&rsquo;ve got this!
            </p>
            <p className="mt-2">For example: &ldquo;Balanced growth on my own terms&rdquo; may be too vague.</p>
            <p>
              Try being more specific: &ldquo;Balanced personal growth on my own terms&rdquo; or &ldquo;Balanced mental
              and physical growth on my own terms&rdquo;
            </p>
          </AlertDescription>
        </Alert>
      )}
    </>
  );
}

export default SuggestGoalResults;
