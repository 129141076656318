import React, { useEffect, useState } from "react";
import { Button } from "@shared/components/shadcn/button";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@shared/components/shadcn/alert";
import { RefreshCw } from "lucide-react";

const UpdatePopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchAndSaveVersion = async () => {
      const timestamp = Date.now();
      const response = await fetch(`/version.json?t=${timestamp}`);
      const latestVersion = await response.json();
      localStorage.setItem("appVersion", JSON.stringify(latestVersion));
    };

    const checkForUpdates = async () => {
      const timestamp = Date.now();
      const response = await fetch(`/version.json?t=${timestamp}`);
      const latestVersion = await response.json();
      const currentVersion = JSON.parse(
        localStorage.getItem("appVersion") || "{}"
      );

      if (latestVersion.sequence !== currentVersion.sequence) {
        setShowPopup(true);
        localStorage.setItem("appVersion", JSON.stringify(latestVersion));
      }
    };

    // Fetch and save initial version without showing popup
    fetchAndSaveVersion();

    const interval = setInterval(checkForUpdates, 900000); // 15 minutes in milliseconds

    return () => clearInterval(interval);
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  if (!showPopup) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50 transition-all duration-300 ease-in-out">
      <Alert variant="default" className="w-80 shadow-lg">
        <RefreshCw className="h-4 w-4" />
        <AlertTitle>Update Available</AlertTitle>
        <AlertDescription className="mt-2">
          A new version of the app is available. Please reload to update.
        </AlertDescription>
        <Button onClick={handleReload} className="mt-4 w-full">
          Reload
        </Button>
      </Alert>
    </div>
  );
};

export default UpdatePopup;