/**
 * v0 by Vercel.
 * @see https://v0.dev/t/fzQG1adgoZw
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { PromptAnswer } from "@shared/types";
import { ReportSection } from "./ReportSection";
import "@/styles/md-container.css";
import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@shadcn/alert-dialog";
import { Button } from "@shadcn/button";
import { Card, CardContent, CardFooter, CardHeader } from "@shadcn/card";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@shadcn/hover-card";
import { ListRestart } from "lucide-react";
import { useState } from "react";

interface DashboardReportProps {
  prompt: PromptAnswer;
}

function RemindHoverCard() {
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  return (
    <>
      <HoverCard
        open={open}
        onOpenChange={(isOpen) => {
          setOpen(isOpen);
        }}
      >
        <HoverCardTrigger asChild>
          <Button variant="outline" className="p-1 h-7 w-7 ml-2">
            <ListRestart className="w-4 h-4" />
          </Button>
        </HoverCardTrigger>
        <HoverCardContent className="w-80">
          <div className="flex justify-between space-x-4">
            <div className="space-y-1">
              <h4 className="text-sm font-semibold">
                Complete your Lifehack 360 Assessment quarterly
              </h4>
              <p className="text-sm">
                Regular assessments help you track progress and adjust your
                goals. This quarterly check-in keeps you aligned with your North
                Star goals and ensures continuous improvement in both personal
                and professional areas.
              </p>
              <Button
                variant="link"
                className="p-0 h-auto font-normal underline"
                onClick={() => {
                  setOpen(false);
                  setAlertOpen(true);
                }}
              >
                Restart your Lifehack 360 Assessment
              </Button>
            </div>
          </div>
        </HoverCardContent>
      </HoverCard>

      <AlertDialog open={alertOpen} onOpenChange={setAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Start a New Assessment?</AlertDialogTitle>
            <AlertDialogDescription>
              Are you ready to start a new Lifehack 360 Assessment? Completing
              this assessment will generate a fresh report from your coach,
              replacing the current one.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction asChild>
              <Link to="/create-big-northstar">Start New Assessment</Link>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default function DashboardReport({ prompt }: DashboardReportProps) {
  const firstUpdatedAt = prompt.responses?.[0]?.updatedAt;

  return (
    <Card className="mt-14">
      <CardHeader>
        <h1 className="mt-10 text-3xl pb-2 font-bold tracking-tight text-primary dark:text-gray-100">
          Your Lifehack 360 Report
        </h1>
        {firstUpdatedAt && (
          <p className="text-lg">
            {new Date(firstUpdatedAt.toDate()).toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            <RemindHoverCard />
          </p>
        )}
      </CardHeader>
      <CardContent className="mt-8 space-y-6">
        {/* Filter responses to only include those with responseTypeId starting with 'dashboard' and sort them by responseTypeId */}
        {prompt.responses
          ?.filter((response) =>
            response.responseTypeId.startsWith("dashboard")
          )
          .sort((a, b) => a.responseTypeId.localeCompare(b.responseTypeId))
          .map((response) => (
            <ReportSection key={response.id} response={response} />
          ))}
      </CardContent>

      <CardFooter className="flex flex-col gap-4">
        <p className="text-sm text-slate-500 mt-2">
          This report is generated using AI technology and should be used as a
          general guide only. While we strive for accuracy, AI may make
          mistakes. You are responsible for verifying information and making
          your own decisions. Any actions taken based on this report are at your
          own discretion and risk.
        </p>
      </CardFooter>
    </Card>
  );
}
