import { useEffect, useRef, RefObject, useCallback } from "react";

export function useScrollToBottom<T extends HTMLElement>(): [
  RefObject<T>,
  RefObject<T>,
  () => void
] {
  const containerRef = useRef<T>(null);
  const endRef = useRef<T>(null);
  const isUserAtBottomRef = useRef(true);

  const scrollToBottom = useCallback(() => {
    if (endRef.current) {
      endRef.current.scrollIntoView({ behavior: "instant", block: "end" });
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const end = endRef.current;

    if (container && end) {
      const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = container;
        isUserAtBottomRef.current = scrollTop + clientHeight >= scrollHeight - 10;
      };

      container.addEventListener("scroll", handleScroll);

      const observer = new MutationObserver(() => {
        if (isUserAtBottomRef.current) {
          scrollToBottom();
        }
      });

      observer.observe(container, {
        childList: true,
        subtree: true,
        characterData: true,
      });

      return () => {
        observer.disconnect();
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollToBottom]);

  return [containerRef, endRef, scrollToBottom];
}