"use client";

/**
 * Custom hook for dynamically resizing a textarea to fit its content.
 * @param {React.RefObject<HTMLTextAreaElement>} textareaRef - Reference to the textarea element.
 * @param {string} textContent - Current text content of the textarea.
 */

import { useEffect } from "react";

export const useDynamicTextareaSize = (
  textareaRef: React.RefObject<HTMLTextAreaElement>,
  textContent: string
): void => {
  useEffect(() => {
    const currentTextarea = textareaRef.current;
    if (currentTextarea) {
      // Temporarily collapse the textarea to calculate the required height.
      currentTextarea.style.height = "0px";
      const contentHeight = currentTextarea.scrollHeight;

      // Adjust the height of the textarea to fit its content.
      currentTextarea.style.height = `${contentHeight}px`;
    }
  }, [textareaRef, textContent]); // Trigger effect on changes to the textarea or its content.
};
