import { useState, useEffect } from "react";
import { PromptAnswer } from "@shared/types";
import { AnswerViewMode } from "./AnswerViewMode";
import { AnswerEditMode } from "./AnswerEditMode";
import { useParams } from "react-router-dom";
import { useFetchPromptAnswerById } from "@/queries/promptAnswers";
import { motion } from "framer-motion";

export function ExistingPromptPage() {
  const { promptAnswerId } = useParams();

  const [form, setForm] = useState<PromptAnswer>();
  const [isEditMode, setIsEditMode] = useState(false);
  const { data, isLoading } = useFetchPromptAnswerById(promptAnswerId ?? '');

  useEffect(() => {
    if (!isLoading && data) {
      setForm(data);
    }
  }, [data, isLoading]);

  if (promptAnswerId === undefined) return null;
  if (isLoading) return null;
  if (!form) return null;

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const toggleEditMode = () => setIsEditMode((prev) => !prev);

  return (
    <motion.div
      className="container"
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 1, scale: 1 }}
    >
      {isEditMode ? (
        <AnswerEditMode formInitialData={form} toggleEditMode={toggleEditMode} />
      ) : (
        <AnswerViewMode form={form} handleEdit={handleEdit} toggleEditMode={toggleEditMode} />
      )}
    </motion.div>
  );
}
