interface LearnIframeProps {
  path?: string;
}

export function LearnIframe({ path }: LearnIframeProps) {
  let src = "https://members.lifehack.org/c/start-here-d2603a/";
  if(path) {
    src = `https://members.lifehack.org/${path}`;
  }

  return (
    <div className="w-full h-full">
      <iframe
        src={src}
        allowFullScreen
        className="w-full h-full"
      ></iframe>
    </div>
  );
}