import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { useAuthContext } from "@/providers/AuthProvider";
import { Button } from "@shadcn/button";
import { Button as StateButton } from "@shared/components/tremor/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@shadcn/form";
import { Input } from "@shadcn/input";
import { Separator } from "@shadcn/separator";
import { Textarea } from "@shadcn/textarea";
import { Skeleton } from "@shadcn/skeleton";
import { toast } from "@shadcn/use-toast";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchUserById, useUpdateUserMutation } from "../../../queries/user";
import { initializeFunctions } from "../../../utils/firebaseConfig";
import { TimezoneSelect } from "./TimezoneSelect";

const settingFormSchema = z.object({
  name: z
    .string()
    .min(3, {
      message: "Full name must be at least 3 characters.",
    })
    .max(80, {
      message: "Full Name must not be longer than 80 characters.",
    }),
  email: z
    .string({
      required_error: "Please select an email to display.",
    })
    .email(),
  aspiration: z.string().min(20),
  timezone: z.string().optional(), 
});

type SettingFormValues = z.infer<typeof settingFormSchema>;

const defaultValues: Partial<SettingFormValues> = {
  aspiration: "",
  email: "",
  name: "",
};

function AccountPage() {
  const [isloadSubscription, setIsLoadSubscription] = useState(false);
  const navigate = useNavigate();
  const { user: authUser, loading } = useAuthContext();

  const uid = authUser?.uid || "";
  const { isPending, data: user, error } = useFetchUserById(uid);
  const updateUser = useUpdateUserMutation();

  const form = useForm<SettingFormValues>({
    resolver: zodResolver(settingFormSchema),
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    if (!user) {
      return;
    }
    console.log('user', user)
    form.setValue("name", user.name);
    form.setValue("email", user.email);
    if (user.aspiration) {
      form.setValue("aspiration", user.aspiration);
    }  
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    form.setValue("timezone", user.timezone || browserTimezone);
  }, [form, user]);

  if (loading) return null;
  if (!authUser) return null;

  if (isPending || !user) {
    return (
      <div className="container mt-12">
        <div className="space-y-8">
          <div className="space-y-2">
            <Skeleton className="h-8 w-48" />
            <Skeleton className="h-4 w-64" />
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <Skeleton className="h-4 w-24" />
              <Skeleton className="h-10 w-full max-w-md" />
            </div>
            <div className="space-y-2">
              <Skeleton className="h-4 w-16" />
              <Skeleton className="h-10 w-full max-w-md" />
              <Skeleton className="h-4 w-72" />
            </div>
            <div className="space-y-2">
              <Skeleton className="h-4 w-48" />
              <Skeleton className="h-24 w-full max-w-md" />
            </div>
            <Skeleton className="h-10 w-32" />
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error</div>;
  }

  function onSubmit(data: SettingFormValues) {
    updateUser.mutate({
      name: data.name,
      aspiration: data.aspiration,
      timezone: data.timezone,
    });

    toast({
      description: "Your settings have been updated.",
    });
    // toast({
    //   title: "You submitted the following values:",
    //   description: (
    //     <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
    //       <code className="text-white">{JSON.stringify(data, null, 2)}</code>
    //     </pre>
    //   ),
    // })
  }

  const handleSignOut = () => {
    navigate("/logoff");
  };

  interface ResponseData {
    status: string;
    url?: string;
    message?: string;
  }
  const handleManageSubClick = () => {
    setIsLoadSubscription(true);
    const functions = initializeFunctions();
    const billingPortalRedirect = httpsCallable(functions, "billingPortalRedirect");
    billingPortalRedirect()
      .then((result) => {
        if (result.data === null) {
          console.log("no data");
          toast({
            title: "Error",
            description: "No data received from the server. Please contact support@lifehack.org for assistance",
            variant: "destructive",
          });
          setIsLoadSubscription(false);
          return;
        }
        console.log("result.data:", result.data);
        const data = result.data as ResponseData;
        if (data.status === "error") {
          toast({
            title: "Error",
            description:
              "An error occurred while fetching subscription details. Please contact support@lifehack.org for assistance",
            variant: "destructive",
          });
        } else if (data.url) {
          window.location.href = data.url;
        } else {
          toast({
            title: "Error",
            description: "Invalid response from the server. Please contact support@lifehack.org for assistance",
            variant: "destructive",
          });
        }
        setIsLoadSubscription(false);
      })
      .catch((error) => {
        console.error("Error in billingPortalRedirect:", error);
        toast({
          title: "Error",
          description:
            "An error occurred while managing your subscription. Please contact support@lifehack.org for assistance",
          variant: "destructive",
        });
        setIsLoadSubscription(false);
      });
  };

  function accountSection() {
    console.log("accountSection:", isloadSubscription);
    return (
      <div className="mt-6 space-y-6">
        <Separator />
        <div>
          <h2 className="text-2xl font-argent mb-2">Your Subscription</h2>
          <p className="text-muted-foreground mb-4">
            Go to your Stripe billing management page to make any changes to your subscription plan.
          </p>
          <StateButton
            className="bg-primary py-2 px-4 hover:bg-primary/90"
            isLoading={isloadSubscription}
            loadingText="Fetching account details..."
            onClick={handleManageSubClick}
          >
            Manage Your Subscription
          </StateButton>
        </div>
        <Separator />
        <div className="pb-6">
          <h2 className="text-2xl font-argent mb-4">Account</h2>
          <Button variant="outline" onClick={handleSignOut}>
            Logout
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-12">
      <div className="mb-6">
        <div className="flex items-center mb-1">
          {/* <ShowSidebarButton /> */}
          <h1 className="text-4xl font-argent">Your Settings</h1>
        </div>
        <p className="text-muted-foreground mb-4">Configure your account settings here.</p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Full Name</FormLabel>
                <FormControl>
                  <Input className="w-full max-w-[420px]" placeholder="Your Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input disabled={true} className="w-full max-w-[420px]" {...field} />
                </FormControl>
                <FormDescription className="text-base">
                  Email used for this account, billing and other Lifehack services.{" "}
                  <span className="font-bold">Cannot be changed.</span>
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="timezone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Timezone</FormLabel>
                <FormControl>
                  <TimezoneSelect value={field.value || ""} onChange={field.onChange} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="aspiration"
            render={({ field }) => (
              <FormItem className="lg:flex">
                <div>
                  <FormLabel>Your Aspiration Statements</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Tell us a little bit about yourself"
                      className="mt-2 w-[420px] h-[200px]"
                      {...field}
                    />
                  </FormControl>
                </div>
                <div className="pt-6 text-muted-foreground max-w-[420px] lg:ml-6">
                  <p className="mb-2">
                    Define Your Aspirations. By sharing your aspirations with us, our AI will craft guiding
                    &ldquo;Northstars&rdquo; and tailored recommendations for your journey.
                  </p>
                  <ul className="ml-4 list-disc">
                    <li>What achievement would truly make you beam with pride?</li>
                    <li>
                      Which challenge or issue have you always felt driven to address, but been unsure of the starting
                      point?
                    </li>
                    <li>Imagine a day in your life five years from now. What defines its perfection?</li>
                  </ul>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Update settings</Button>
        </form>
      </Form>
      {accountSection()}
    </div>
  );
}

export default AccountPage;
