import { PlayCircle } from "lucide-react";
import emptyStateDashboard from "../../../images/empty/add-northstar.png";
import VimeoVideoEmbed from "../../components/VimeoVideoEmbed";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@shadcn/dialog";

export function NorthStarEmptyState() {
  return (
    <div className="w-full text-center">
      <img src={emptyStateDashboard} className="block mx-auto w-72" />
      <div className="font-bold text-xl">
        Use the Time Flow System by Managing
        <br />
        your North Stars, Initiatives & Focus Blocks
      </div>
      <Dialog>
        <DialogTrigger className="w-72 mx-auto mt-4 mb-2 rounded-sm bg-gray-100 p-3 text-sm text-gray-600 font-bold text-center hover:bg-gray-200">
          <PlayCircle size="16" className="inline align-middle mr-1" /> Welcome to the Time Flow System
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Welcome to the Time Flow System by LifeHack</DialogTitle>
            <DialogDescription className="pt-4">
              <VimeoVideoEmbed videoId="852462647" title="Welcome to the Time Flow System" />
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <div className="text-gray-500 text-sm">
        <a
          href="https://help.lifehack.org/article/115-what-is-the-time-flow-system"
          target="_blank"
          className="text-gray-500 underline"
        >
          Learn More
        </a>
      </div>
    </div>
  );
}
