import { useState, useEffect, useRef } from "react";
import { setupConfig, aiApiUrl } from "./apiUtils";

const streamUrl = `${aiApiUrl}/v1/test/stream`;

const fetchStreamText = async (
  userInput: string,
  onData: (chunk: string) => void,
  promptId?: string,
  parameters?: Record<string, string>
): Promise<{ traceId: string }> => {
  const config = await setupConfig();

  const response = await fetch(streamUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...config.headers,
    },
    body: JSON.stringify({ input: userInput, promptId, parameters }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  if (!response.body) {
    throw new Error("No response body");
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  let done = false;

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    const chunk = decoder.decode(value, { stream: true });
    onData(chunk);
  }

  const traceId = response.headers.get('X-Lf-Trace-Id') || '';
  // Log all headers for debugging
  console.log('All response headers:');
  response.headers.forEach((value, name) => {
    console.log(`${name}: ${value}`);
  });

  return { traceId };
};

export const useStreamText = (
  userInput: string,
  promptId?: string,
  parameters?: Record<string, string>
): { isStreaming: boolean; isComplete: boolean; data: string; traceId: string } => {
  const [data, setData] = useState<string>("");
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [traceId, setTraceId] = useState<string>("");
  const isStreamingRef = useRef(false);

  useEffect(() => {
    if (!userInput || isStreamingRef.current) return;

    const onData = (chunk: string) => {
      setData(prevData => prevData + chunk);
    };

    const fetchData = async () => {
      setIsStreaming(true);
      isStreamingRef.current = true;
      setData("");
      setIsComplete(false);
      setTraceId("");

      try {
        const { traceId } = await fetchStreamText(userInput, onData, promptId, parameters);
        setTraceId(traceId);
        setIsComplete(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsStreaming(false);
        isStreamingRef.current = false;
      }
    };

    fetchData();
  }, [userInput, promptId, parameters]);

  return { isStreaming, isComplete, data, traceId };
};