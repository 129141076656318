/**
 * AddGoalButton component
 *
 * This component is used for embedding into markdown report.
 *
 * @description - A button to add a goal
 * @component
 */
import { Button } from "@shadcn/button";
import { useState, useEffect } from "react";
import { useAddGoalMutation, useFetchGoals } from "@/queries/goals";
import { Goal } from "@shared/types";
import { useUpdateUserMutation } from "@/queries/user";

interface AddGoalButtonProps {
  goalName: string;
  addedByResponseId: string;
}

export function AddGoalButton({ goalName, addedByResponseId }: AddGoalButtonProps) {
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [goalId, setGoalId] = useState<string | null>(null);
  const addGoalMutation = useAddGoalMutation();
  const { data: userGoals, isLoading: isLoadingGoals } = useFetchGoals();
  const updateUserMutation = useUpdateUserMutation();

  useEffect(() => {
    const goal = userGoals?.find((goal: Goal) => goal.addedByResponseId === addedByResponseId);
    if (goal) {
      setIsAdded(true);
      setGoalId(goal.id);
    }
  }, [userGoals, addedByResponseId]);

  const handleAddGoal = async () => {
    try {
      const newGoal = await addGoalMutation.mutateAsync({ name: goalName, addedByResponseId });
      setIsAdded(true);
      setGoalId(newGoal.id);
      await updateUserMutation.mutate({
        bigGoalId: newGoal.id,
      });
    } catch (error) {
      console.error("Failed to add goal", error);
    }
  };

  if (isLoadingGoals) {
    return <div>Loading...</div>;
  }

  return (
    <div className="my-4">
      <Button onClick={handleAddGoal} disabled={isAdded}>
        {isAdded ? "Big Northstar Added" : "Add Big Northstar"}
      </Button>
      {isAdded && goalId && (
        <a href={`/goals/${goalId}`} className="text-sm underline ml-4">
          View
        </a>
      )}
    </div>
  );
}