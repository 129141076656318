interface AiTypeButtonProps {
  name: string;
  isActive: boolean;
  icon: string;
  onClick: () => void;
}

export function AiTypeButton({ name, isActive, icon, onClick }: AiTypeButtonProps) {
  const decodedIcon = icon.startsWith("data:image/svg+xml;base64,") ? atob(icon.split(",")[1]) : icon;

  return (
    <button
      onClick={onClick}
      className={`shadow border py-4 px-4 mb-4 rounded-xl w-full flex items-center text-left ${isActive ? "bg-primary text-slate-200 hover:bg-primary" : "hover:bg-slate-50 text-slate-600"}`}
    >
      <span className="mr-2" dangerouslySetInnerHTML={{ __html: decodedIcon }}></span>
      {name}
    </button>
  );
}
