import { Button } from "@shadcn/button"
import Markdown from "markdown-to-jsx"
import "@/styles/md-container.css"

interface IntroductionProps {
  onContinue: () => void
}

export default function Introduction({ onContinue }: IntroductionProps) {
  return (
    <div className="space-y-6">
      <Markdown className="md-container">
{`
# Step 1: Welcome to Your LifeHack 360 Assessment!

You're about to embark on a transformative journey of self-discovery and growth. This isn't just another online quiz - it's a powerful, interactive tool designed to help you uncover your true priorities and create a personalized roadmap for meaningful change.

Here's what your journey looks like:

1. **In-Depth Assessment (15-20 minutes):** 
   - You'll answer 20 thought-provoking questions about your current challenges, goals, and lifestyle.
   - Be as detailed and honest as possible - your answers are the foundation for everything that follows.

2. **Interactive Northstar Goal Setting:**
   - This is the heart of the process. We'll work together to craft a big, inspiring "Northstar" goal that will guide your personal growth.
   - You'll have multiple opportunities to refine this goal. It's normal to go back and forth, adjusting your answers and exploring different goals.
   - Take your time here - getting this right is crucial for your journey.

3. **Personalized Action Plan:**
   - Once you're truly excited about your Northstar Goal, we'll generate a detailed roadmap to help you achieve it.
   - This includes specific milestones, strategies, and accountability measures tailored to you.

Remember, this process is designed to be flexible and iterative. You're encouraged to revisit and refine your answers as many times as needed. The deeper your reflections and the more detailed your responses, the more powerful and accurate your personalized plan will be.

Are you ready to take the first step towards transformative change? Let's begin!
`}
      </Markdown>
      <Button onClick={onContinue} className="bg-primary hover:bg-primary/90">Start My Journey</Button>
    </div>
  )
}