"use client"

import { useMemo } from "react"
import { Progress } from "@shadcn/progress"
import { Card, CardContent, CardHeader, CardTitle } from "@shadcn/card"
import { CheckCircle2, Loader2 } from "lucide-react"

export type StreamData =
  | { type: "progress"; reportKey: string; title: string; current: number; total: number }
  | { type: "complete"; message: string }

type ReportProgressStreamProps = {
  streamData: StreamData[]
}

export default function ReportProgressStream({ streamData }: ReportProgressStreamProps) {
  const reports = useMemo(() => {
    return streamData.filter((data): data is Extract<StreamData, { type: "progress" }> => 
      data.type === "progress"
    );
  }, [streamData]);

  const isComplete = useMemo(() => {
    return streamData.some(data => data.type === "complete");
  }, [streamData]);

  const totalAssessments = reports.length > 0 ? reports[0].total : 1;
  const overallProgress = reports.length > 0 ? (reports.length / totalAssessments) * 100 : 0

  const latestReportIndex = reports.length - 1;

  return (
    <div className="py-4">
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Report Generation Progress</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-4">
            <div className="text-sm font-medium mb-2">Overall Progress</div>
            <Progress value={overallProgress} className="w-full" />
            <div className="text-sm text-right mt-1">{Math.round(overallProgress)}%</div>
          </div>
          {isComplete && (
            <div className="text-sm font-medium text-green-600 mt-2">
              All reports generated successfully
            </div>
          )}
        </CardContent>
      </Card>
      <div className="space-y-4">
        {reports.map((report, index) => (
          <Card key={report.reportKey}>
            <CardContent className="flex items-center justify-between py-4">
              <div className="flex items-center">
                {index < latestReportIndex || (index === latestReportIndex && report.current === report.total) ? (
                  <CheckCircle2 className="mr-2 h-4 w-4 text-green-500" />
                ) : (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                <span className="text-sm font-medium">{report.title}</span>
              </div>
              <div className="text-sm">
                {index < latestReportIndex || (index === latestReportIndex && report.current === report.total) ? 
                  "Complete" : "Generating"}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}