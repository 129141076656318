import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { initializeFirestore, initializeAuth } from "../utils/firebaseConfig";
import { Goal, GoalProjects, Project } from "@shared/types";
import { captureException } from "../utils/logging";

export { useFetchGoals, useFetchGoalByGoalId, useFetchActiveGoalsWithProjects, useFetchGoalByProjectId };

const useFetchGoals = () => {
  return useQuery<Goal[], Error>({
    queryKey: ["goals"],
    queryFn: async () => fetchGoals(),
  });
};

const useFetchGoalByGoalId = (goalId: string | undefined) => {
  return useQuery<Goal, Error>({
    queryKey: ["goals", goalId],
    queryFn: async () => fetchGoal(goalId),
    enabled: !!goalId,
  });
};

const useFetchActiveGoalsWithProjects = () => {
  return useQuery<GoalProjects[], Error>({
    queryKey: ["goals", "projects"],
    queryFn: async () => fetchActiveGoalsWithProjects(),
  });
};

const useFetchGoalByProjectId = (projectId: string | undefined) => {
  return useQuery<Goal, Error>({
    queryKey: ["goals", "byProject", projectId],
    queryFn: async () => fetchGoalByProjectId(projectId),
    enabled: !!projectId,
  });
};

export function useUpdateGoalMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (goal: Goal) => {
      await updateGoal(goal.id, goal);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["goals"] });
    },
  });
}
export function useArchiveGoalMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (goalId: string) => {
      await updateGoal(goalId, { status: "archived" });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["goals"] });
    },
  });
}

export function useAddGoalMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (goal: Partial<Goal>) => {
      return await addGoal(goal);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["goals"] });
    },
  });
}

async function addGoal(goal: Partial<Goal>) {
  const { name, details, ...restOfGoal } = goal;
  if (!name) throw new Error("Goal name is required");

  try {
    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const goalCollectionRef = collection(db, "goals");
    const newGoal = await addDoc(goalCollectionRef, {
      name,
      details: details ?? "",
      userId: uid,
      status: "active",
      ...restOfGoal,
    });
    return { id: newGoal.id };
  } catch (error) {
    captureException("error adding goals", error as Error);
    throw error;
  }
}

async function updateGoal(goalId: string, fieldsToUpdate: Partial<Goal>) {
  try {
    const db = initializeFirestore();
    const goalRef = doc(db, "goals", goalId);
    await updateDoc(goalRef, fieldsToUpdate);
  } catch (error) {
    captureException("error updating goal", error as Error);
    throw error;
  }
}

async function fetchGoals() {
  try {
    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const goalsCollectionRef = collection(db, "goals");
    const querySnapshot = await getDocs(
      query(goalsCollectionRef, where("userId", "==", uid), where("status", "==", "active"), orderBy("name"))
    );

    const goals: Goal[] = [];
    querySnapshot.forEach((doc) => {
      const goalData = doc.data() as Goal;
      // if (goalData.status !== 'archived') { // Only add goal if status is not 'archived'
      goals.push({
        ...goalData,
        id: doc.id,
      });
      // }
    });

    return goals;
  } catch (error) {
    captureException("error fetching goals", error as Error);
    throw error;
  }
}

async function fetchGoal(goalId: string | undefined) {
  if (!goalId) throw new Error("Goal ID is required");

  try {
    const db = initializeFirestore();
    const goalRef = doc(db, "goals", goalId);
    const goalDoc = await getDoc(goalRef);

    if (goalDoc.exists()) {
      const goalData = goalDoc.data() as Goal;
      return {
        ...goalData,
        id: goalDoc.id,
      };
    } else {
      throw new Error("Goal not found");
    }
  } catch (error) {
    captureException("error fetching goal", error as Error);
    throw error;
  }
}

async function fetchGoalByProjectId(projectId: string | undefined) {
  if (!projectId) throw new Error("Project ID is required");

  try {
    const db = initializeFirestore();
    const projectRef = doc(db, "projects", projectId);
    const projectDoc = await getDoc(projectRef);

    if (!projectDoc.exists()) {
      throw new Error("Project not found");
    }

    const projectData = projectDoc.data() as Project;
    const goalId = projectData.goalId;

    if (!goalId) {
      throw new Error("Project is not associated with a goal");
    }

    return await fetchGoal(goalId);
  } catch (error) {
    captureException("error fetching goal by project id", error as Error);
    throw error;
  }
}

export const fetchActiveGoalsWithProjects = async (): Promise<GoalProjects[]> => {
  try {
    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const goalsCollectionRef = collection(db, "goals");
    const querySnapshot = await getDocs(
      query(goalsCollectionRef, where("status", "==", "active"), where("userId", "==", uid), orderBy("name"))
    );

    const goals: GoalProjects[] = [];
    querySnapshot.forEach((doc) => {
      const goalData = doc.data() as GoalProjects;
      goals.push({
        ...goalData,
        id: doc.id,
        projects: [] as Project[], // Initialize projects array
      });
    });

    const projectsCollectionRef = collection(db, "projects");
    const querySnapshotProjects = await getDocs(
      query(projectsCollectionRef, where("completed", "==", false), where("userId", "==", uid), orderBy("name"))
    );

    querySnapshotProjects.forEach((doc) => {
      const projectData = doc.data() as Project;
      const goalId = projectData.goalId;
      const goal = goals.find((goal) => goal.id === goalId);
      if (goal) {
        goal.projects.push({
          ...projectData,
          id: doc.id,
        });
      }
    });

    return goals;
  } catch (error) {
    captureException("Error fetching goals and projects from Firestore", error as Error);
    throw error;
  }
};