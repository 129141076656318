import { AlertCircle } from "lucide-react";
import React, { useCallback, useMemo } from "react";
import { Offline } from "react-detect-offline";
import { Panel, PanelGroup } from "react-resizable-panels";

import { Alert, AlertDescription, AlertTitle } from "@shadcn/alert";
import { Drawer, DrawerContent } from "@shadcn/drawer";
import { Toaster } from "@shadcn/toaster";

import { useChatContext } from "@/web/hooks/useChatContext";
import ChatWindow from "../pages/Chat/ChatWindow";
import { Navbar } from "./Navbar";
import { useMediaQuery } from 'usehooks-ts'

type MainContainerProps = {
  children: React.ReactNode;
};

function OfflineAlertBox() {
  return (
    <Alert className="container mt-4 mb-4" variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>You have no connection</AlertTitle>
      <AlertDescription>
        Your data may not be saved. Please check your connection.
      </AlertDescription>
    </Alert>
  );
}

const MainContainer = ({ children }: MainContainerProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { selectedGoal, setSelectedGoal, isChatVisible, setIsChatVisible } =
    useChatContext();

  const handleClose = useCallback(() => {
    console.log("handleClose");
    setIsChatVisible(false);
    setSelectedGoal(null);
  }, [setIsChatVisible, setSelectedGoal]);

  const chatWindow = useMemo(() => (
    <ChatWindow goal={selectedGoal ?? ""} onClose={handleClose} />
  ), [selectedGoal, handleClose]);

  const handleDrawerOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen && isMobile) {
        setIsChatVisible(false);
      } else if (isOpen) {
        setIsChatVisible(true);
      }
    },
    [isMobile, setIsChatVisible]
  );

  return (
    <div className="flex h-screen flex-col">
      <Toaster />
      <Navbar />
      <PanelGroup direction="horizontal" className="flex-1">
        <Panel
          defaultSize={100}
          minSize={60}
          className="transition-[flex-basis] duration-300 ease-in-out"
        >
          <div className="h-full overflow-y-auto">
            <div className="mx-auto w-full max-w-[1000px] space-y-8 md:p-4">
              <Offline>
                <OfflineAlertBox />
              </Offline>
              {children}
            </div>
          </div>
        </Panel>
        <Panel
          defaultSize={0}
          minSize={30}
          collapsible={true}
          className="transition-[flex-basis] duration-300 ease-in-out"
          style={
            {
              "--panel-size": isChatVisible ? "500px" : "0px",
              flexBasis: "var(--panel-size)",
            } as React.CSSProperties
          }
        >
          <div className="h-full border-l">{chatWindow}</div>
        </Panel>
      </PanelGroup>
      <Drawer
        open={isMobile && isChatVisible}
        onOpenChange={handleDrawerOpenChange}
      >
        <DrawerContent className="h-[95%] max-h-[95%]">
          {chatWindow}
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default MainContainer;
