type Props = {
    videoId: string;
    title: string;
};

function VimeoVideoEmbed({videoId, title}: Props) {

  return (
    <>
      <div className="relative p-0 pt-[56.25%]">
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          className="absolute top-0 left-0 w-full h-[100%]"
          title={title}
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  );
}

export default VimeoVideoEmbed;
