import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@shadcn/tooltip";
import { Square, SquareCheckBig } from "lucide-react";

interface ProjectTasksDescProps {
  tasks: {
    completed: number;
    incomplete: number;
  };
}

function ProjectTasksDesc({ tasks }: ProjectTasksDescProps) {
  return (
    <div>
      <div className="text-center text-sm">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <p className="text-gray-400 mt-1 flex items-center">
                <SquareCheckBig size={20} className="mr-2  flex-shrink-0" />
                <span className="font-bold">{tasks.completed}</span>&nbsp;completed
              </p>
            </TooltipTrigger>
            <TooltipContent>
              <p># of Completed Actions</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="text-center text-sm border-gray-300">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <p className="text-gray-400 mt-1 flex items-center">
                <Square size={20} className="mr-2 flex-shrink-0" />
                <span className="font-bold">{tasks.incomplete}</span>&nbsp;incomplete
              </p>
            </TooltipTrigger>
            <TooltipContent>
              <p># of Incomplete Actions</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
}

export default ProjectTasksDesc;
