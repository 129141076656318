import { initializeMessaging, initializeAuth } from "./firebaseConfig";
import { getToken, onMessage } from "firebase/messaging";
import { doc, setDoc } from "firebase/firestore";
import { initializeFirestore } from "./firebaseConfig";

export function requestMessagingPermission() {
  const messaging = initializeMessaging();
  const auth = initializeAuth();

  Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");

        // Get the Instance ID token for the first time
        return getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });
      } else {
        console.error("Unable to get permission to notify.");
      }
    })
    .then((token) => {
      console.log("FCM Token:", token);
      // Subscribe to auth state changes
      auth.onAuthStateChanged((user) => {
        if (user) {
          console.log("User ID: ", user.uid);
          const db = initializeFirestore();
          setDoc(
            doc(db, "users", user.uid),
            {
              fcmToken: token,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            { merge: true }
          );
        }
      });
      onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
        // ...
      });
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. ", err);
    });
}

export function isPermissionAsked() {
  if (Notification.permission === "granted" || Notification.permission === "denied") {
    return true;
  } else {
    return false;
  }
}
