import { useState, useEffect } from "react";
import { Bell } from "lucide-react";
import { Button } from "@shadcn/button";
import { Alert, AlertDescription, AlertTitle } from "@shadcn/alert";
import { requestMessagingPermission } from "@/utils/requireMessagingPermission";
import { useFeatureFlagEnabled } from "posthog-js/react";

export const PushPermission = () => {
  const [permission, setPermission] = useState<NotificationPermission | null>(null);
  const featureEnabled = useFeatureFlagEnabled("push-notification");

  useEffect(() => {
    if (typeof window !== "undefined" && "Notification" in window) {
      setPermission(Notification.permission);

      const handlePermissionChange = () => {
        setPermission(Notification.permission);
      };

      navigator.permissions.query({ name: "notifications" }).then((permissionStatus) => {
        permissionStatus.onchange = handlePermissionChange;
      });

      return () => {
        navigator.permissions.query({ name: "notifications" }).then((permissionStatus) => {
          permissionStatus.onchange = null;
        });
      };
    }
  }, []);

  const handleEnableClick = async () => {
    if (typeof window !== "undefined" && "Notification" in window) {
      await requestMessagingPermission();
      setPermission(Notification.permission);
    }
  };

  if (!permission || permission === "granted" || !featureEnabled) {
    return null;
  }

  return (
    <Alert className="flex items-start mb-8">
      <Bell className="h-4 w-4 mr-2" />
      <div className="flex-grow">
        <AlertTitle className="flex items-center">Enable Push Notifications</AlertTitle>
        <AlertDescription className="mt-1">
          Stay on track with your 360 Report. Enable push notifications for personalized reminders?
        </AlertDescription>
      </div>
      <Button className="ml-4 flex-shrink-0" onClick={handleEnableClick}>
        Enable
      </Button>
    </Alert>
  );
};
