import { useNavigate } from "react-router-dom";
import { Button } from "@shadcn/button";
import ProjectTimeBlocksDesc from "./ProjectTimeBlocksDesc";

import { CalendarPlus } from "lucide-react";
import { AddTimeBlockDialog } from "../../components/AddTimeBlockDialog";
import { Card, CardContent, CardHeader, CardTitle } from "@shadcn/card";

import emptyStateAddInitiatives from "../../../images/empty/add-initiatives.png";

import { useFetchActiveProjectsByGoalId } from "../../../queries/projects";
import ProjectTasksDesc from "./ProjectTasksDesc";

interface ActiveProjectsProps {
  goalId: string;
}

function ActiveProjects({ goalId }: ActiveProjectsProps) {
  const navigate = useNavigate();

  const { isPending, isError, data, error } = useFetchActiveProjectsByGoalId(goalId);

  if (isPending || !data) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  if (data.length === 0) {
    return (
      <div className="flex items-start justify-center h-full">
        <div className="w-full text-center">
          <img src={emptyStateAddInitiatives} className="mx-auto mt-4 mb-4 w-64" />
          <div className="font-bold text-xl">Create your first Initiative</div>
          <div className="text-gray-500 mt-2">
            What projects can you tackle to bring you closer to this Northstar?{" "}
            <a
              href="https://help.lifehack.org/article/116-what-is-an-initiative"
              target="_blank"
              className="text-gray-500 underline"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="projects" className="grid grid-cols-1 gap-4 mt-6 lg:grid-cols-2 xl:grid-cols-3">
      {data.map((project) => (
        <Card
          key={project.id}
          onClick={() => navigate(`/projects/${project.id}`)}
          className="relative group cursor-pointer flex flex-col"
        >
          <CardHeader className="flex-grow">
            <div>
              <CardTitle className="text-lg leading-tight p-0 md:text-2xl md:leading-normal text-gray-800 font-argent pr-8">
                {project.name}
              </CardTitle>
            </div>
            <div onClick={(e) => e.stopPropagation()} className="absolute right-0 -top-1.5">
              <AddTimeBlockDialog projectId={project.id}>
                <Button
                  variant={"default"}
                  className="opacity-25 group-hover:opacity-100 transition-opacity duration-200 text-xs rounded-none rounded-tr-lg rounded-bl-lg"
                >
                  <CalendarPlus className="h-4 w-4" />
                </Button>
              </AddTimeBlockDialog>
            </div>
          </CardHeader>
          <CardContent className="pt-3 pb-3 md:pt-4 md:pb-4 w-full mt-auto flex justify-between">
            <ProjectTimeBlocksDesc projectId={project.id} />
            <ProjectTasksDesc
              tasks={{
                completed: project.numCompletedTasks ?? 0,
                incomplete: project.numIncompleteTasks ?? 0,
              }}
            />
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

export default ActiveProjects;
