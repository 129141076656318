import { AlertTriangle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@shadcn/alert";
import { cn } from "../lib/utils";

interface WarningBoxProps {
  subject?: string;
  errorMessage: string;
  className?: string;
}

function WarningBox({ subject = "Heads up", errorMessage, className }: WarningBoxProps) {
  return (
    <Alert className={cn("mb-2 bg-red-100 border-none text-slate-600", className)}>
      <AlertTriangle className="h-4 w-4" />
      <AlertTitle className="font-bold">{subject}</AlertTitle>
      <AlertDescription className="leading-snug">{errorMessage}</AlertDescription>
    </Alert>
  );
}

export default WarningBox;
