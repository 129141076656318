import React, { useEffect } from "react";
import { CalendarClock, Clock } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@shadcn/tooltip";
import { useFetchTimeblocksByProjectId } from "../../../queries/timeblocks";

interface ProjectTimeBlocksDescProps {
  projectId: string;
}

function ProjectTimeBlocksDesc({ projectId }: ProjectTimeBlocksDescProps) {
  const { data: timeblocks } = useFetchTimeblocksByProjectId(projectId);
  const [count, setCount] = React.useState(0);
  const [totalDuration, setTotalDuration] = React.useState(0);

  useEffect(() => {
    if (timeblocks) {
      setCount(timeblocks.length);
      setTotalDuration(timeblocks.reduce((total, timeblock) => total + timeblock.duration, 0));
    }
  }, [timeblocks]);

  return (
    <div className="text-left text-sm">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
          <p className="text-gray-400 mt-1 flex items-center">
              <CalendarClock size={20} className="flex-shrink-0 mr-2" />
              <span className="font-bold">{count}</span>&nbsp;focus block{count > 1 ? "s" : ""}
            </p>
          </TooltipTrigger>
          <TooltipContent>
            <p>Total # of Focus Blocks for this Initiative</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <p className="text-gray-400 mt-1 flex items-center">
              <Clock size={20} className="mr-2 flex-shrink-0" />
              <span>
                <span className="font-bold">{totalDuration}</span>&nbsp;hr{totalDuration > 1 ? "s" : ""} invested
              </span>
            </p>
          </TooltipTrigger>
          <TooltipContent>
            <p>Total # of Hours invested so far</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}

export default ProjectTimeBlocksDesc;