import { useEffect, useState } from 'react';
import { useAuthContext } from "@/providers/AuthProvider";
import { useFetchUserById } from "@/queries/user";

export const useGetName = () => {
  const { user: authUser } = useAuthContext();
  const uid = authUser?.uid || '';
  const { data: user, isPending: isUserPending } = useFetchUserById(uid);
  const [firstName, setFirstName] = useState<string>('');

  useEffect(() => {
    if (user && !isUserPending) {
      user.name && setFirstName(user.name.split(" ").length > 1 ? user.name.split(" ")[0] : user.name);
    }
  }, [user, isUserPending]);

  return { fullName: user?.name, firstName };
};
