import { Timeblock } from "@shared/types";

// Function to group the timeblocks by date (and only in the future)
export const upcomingTimeblockgroupByDate = (blocks: Timeblock[]) => {
    const grouped = blocks.reduce<Record<string, Timeblock[]>>((acc, block) => {
      const startTime = new Date(block.startTime); // converted from number
      // const endTime = new Date(startTime.getTime() + block.duration * 60 * 60 * 1000); // calculate end time
      const day = startTime.toDateString();
      const currentTime = new Date();
  
      // Create date objects for comparison (at 00:00 hours)
      const currentDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
      const startDay = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
  
      // Include timeblocks that are on current day (even if ended) or in the future
      if (startDay.getTime() >= currentDay.getTime()) {
        if (!acc[day]) {
          acc[day] = [];
        }
        acc[day].push(block);
        // Sort timeblocks within each day
        acc[day].sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()); // converted to number
      }
  
      return acc;
    }, {});
    
    return Object.entries(grouped).sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime());
  };

  export const upcomingTimeblocksGroupByDate = (blocks: Timeblock[]): Record<string, Timeblock[]> => {
    const grouped = blocks.reduce<Record<string, Timeblock[]>>((acc, block) => {
      const startTime = new Date(block.startTime); // converted from number
      // const endTime = new Date(
      //   startTime.getTime() + block.duration * 60 * 60 * 1000
      // ); // calculate end time
      const day = startTime.toDateString();
      const currentTime = new Date();
  
      // Create date objects for comparison (at 00:00 hours)
      const currentDay = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate()
      );
      const startDay = new Date(
        startTime.getFullYear(),
        startTime.getMonth(),
        startTime.getDate()
      );
  
      // Include timeblocks that are on current day (even if ended) or in the future
      if (startDay.getTime() >= currentDay.getTime()) {
        if (!acc[day]) {
          acc[day] = [];
        }
        acc[day].push(block);
        // Sort timeblocks within each day
        acc[day].sort((a, b) => a.startTime.getTime() - b.startTime.getTime()); // converted to number
      }
  
      return acc;
    }, {});
  
    return Object.fromEntries(
        Object.entries(grouped).sort(
          (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
        )
      );
  }