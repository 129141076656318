import { useEffect, useState } from "react";
import { initializeAuth } from "@/utils/firebaseConfig";
import { useNavigate } from "react-router-dom";

export default function Logoff() {
  const navigate = useNavigate();
  const auth = initializeAuth();
  const [loggedOff, setLoggedOff] = useState(false);

  useEffect(() => {
    if (!loggedOff) {
      (async () => {
        // TODO: See if we need to remove presisted states
        await auth.signOut();
        setLoggedOff(true);
        navigate("/login", { replace: true });
      })();
    }
  }, [loggedOff, auth, navigate]);

  return <></>;
}
