import { Prompt } from '@shared/types'
import { Answers } from './onboardingStore'

export function mergeAnswersWithAssessmentData(
  answers: Answers,
  assessmentData: Prompt
): Prompt {
  const updatedQuestions = assessmentData.questions.map((question, index) => {
    const answerId = `question-${index}-${question.type}`
    const answer = answers[answerId]

    return {
      ...question,
      answer: answer ?? null
    }
  })

  return {
    ...assessmentData,
    questions: updatedQuestions
  }
}
