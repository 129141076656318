import { useAuthContext } from "@/providers/AuthProvider";
import "@/styles/md-container.css";
import { Button } from "@shadcn/button";
import { Textarea } from "@shadcn/textarea";
import { useChat } from "ai/react";
import { ChevronsRight, SendHorizonal } from "lucide-react";
import { useCallback, useEffect, useRef, useState, KeyboardEvent } from "react";
import { useChatContext } from "@/web/hooks/useChatContext";
import { Message } from "./Message";
import { EmptyState } from "./EmptyState"; // Assume we'll create this component
import { useScrollToBottom } from "./useScrollToBottom";

function LoadingIndicator() {
  return (
    <div className="flex items-center space-x-1 animate-pulse p-4">
      <div className="w-1 h-1 bg-gray-500 rounded-full"></div>
      <div className="w-1 h-1 bg-gray-500 rounded-full animation-delay-150"></div>
      <div className="w-1 h-1 bg-gray-500 rounded-full animation-delay-300"></div>
    </div>
  );
}

function ChatInput({
  input,
  isLoading,
  onInputChange,
  onSubmit,
  textareaRef,
}: {
  input: string;
  isLoading: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}) {
  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    }
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [input, adjustTextareaHeight]);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSubmit(e as any);
    }
  };

  return (
    <div className="flex items-end gap-2">
      <Textarea
        ref={textareaRef}
        value={input}
        onChange={onInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Message Lifehack AI Coach"
        className="flex-grow min-h-[40px] max-h-[200px] resize-none bg-transparent border-none focus-visible:ring-0 focus-visible:ring-offset-0 focus:outline-none overflow-y-auto"
      />
      <Button
        onClick={(e) => onSubmit(e as any)}
        className="rounded-full flex-shrink-0"
        disabled={isLoading}
      >
        <SendHorizonal size={16} />
      </Button>
    </div>
  );
}

function ChatHeader({ onClose }: { onClose: () => void }) {
  return (
    <div className="flex items-center justify-between">
      <div
        onClick={onClose}
        className="cursor-pointer w-10 h-10 flex items-center justify-center"
      >
        <ChevronsRight size={16} />
      </div>
      <span className="flex-grow font-semibold">Ask AI Coach</span>
    </div>
  );
}

function ChatWindow({
  goal = "",
  onClose,
}: {
  goal?: string;
  onClose: () => void;
}) {
  const { token } = useAuthContext();
  const { setIsChatVisible, setSelectedGoal, setMessages, messages } =
    useChatContext();
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [messagesContainerRef, messagesEndRef] =
    useScrollToBottom<HTMLDivElement>();

  const {
    input,
    setInput,
    handleInputChange,
    handleSubmit,
    isLoading,
    messages: chatMessages,
  } = useChat({
    api: process.env.REACT_APP_AI_API_URL + "/v1/chats/dashboard",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    initialMessages: messages,
    initialInput: goal,
    keepLastMessageOnError: true,
    onResponse: () => setIsWaitingForResponse(false),
    onFinish: () => handleFinish(),
  });

  const chatMessagesRef = useRef(chatMessages);

  useEffect(() => {
    chatMessagesRef.current = chatMessages;
  }, [chatMessages]);

  function handleFinish() {
    setMessages(chatMessagesRef.current);
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsWaitingForResponse(true);
    // Call the original handleSubmit
    handleSubmit(e);
  };

  useEffect(() => {
    setInput(goal);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [goal, setInput]);

  const handleClose = () => {
    setIsChatVisible(false);
    setSelectedGoal(null);
    onClose();
  };

  return (
    <div className="w-full px-6 py-4 overflow-hidden flex flex-col h-full">
      <ChatHeader onClose={handleClose} />
      <div
        className="flex-grow overflow-y-auto overflow-x-hidden"
        ref={messagesContainerRef}
      >
        {chatMessages.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            {chatMessages.map((message) => {
              return (
                <div key={message.id}>
                  <Message message={message} />
                </div>
              );
            })}
            {isWaitingForResponse && <LoadingIndicator />}
          </>
        )}
        <div
          ref={messagesEndRef}
          className="flex-shrink-0 min-w-[24px] min-h-[24px]"
        />
      </div>
      <div className="w-full p-4 bg-slate-100 rounded-md mt-auto">
        <ChatInput
          input={input}
          isLoading={isLoading}
          onInputChange={handleInputChange}
          onSubmit={handleFormSubmit}
          textareaRef={textareaRef}
        />
      </div>
      <p className="text-xs text-gray-500 mt-2 text-center">
        AI can make mistakes and should be only used as a reference.
      </p>
    </div>
  );
}

export default ChatWindow;
