import { Button } from "@shadcn/button";
import {
  DialogDescription,
  DialogFooter,
  DialogHeader
} from "@shadcn/dialog";
import { Textarea } from "@shadcn/textarea";
import { useEffect, useState } from "react";

import { useAuthContext } from "@/providers/AuthProvider";
import { usePostHog } from "posthog-js/react";
import { useFetchUserById, useUpdateUserMutation } from "../../../queries/user";
import SuggestGoalResults from "./SuggestGoalResults";

export function SuggestGoalButtonDialog() {
  const { user: authUser } = useAuthContext();

  const uid = authUser?.uid || "";

  const { data: user } = useFetchUserById(uid);
  const updateUser = useUpdateUserMutation();

  const [textareaValue, setTextareaValue] = useState("");

  const [lastSubmittedValue, setLastSubmittedValue] = useState("");
  const [showResults, setShowResults] = useState(false);

  const posthog = usePostHog();

  useEffect(() => {
    if (user && user.aspiration) {
      setTextareaValue(user.aspiration);
    }
  }, [user]);

  const getGoalsAndSaveAspiration = () => {
    posthog?.capture("app_ai_executed", { command_type: "suggest_goal" });
    setShowResults(true);
    setLastSubmittedValue(textareaValue); // Set the last submitted value
    updateUser.mutate({
      aspiration: textareaValue,
    });
  };

  // const resetTemplate = () => {
  //   setTextareaValue(template);
  //   dispatch(setAspiration(template));
  // };

  // const resetView = () => {
  //   setTextareaValue(user?.aspiration || "");
  //   setShowResults(false); // Reset showResults
  // };

  return (
    // <Dialog
    //   open={open}
    //   onOpenChange={(state) => {
    //     if (!state) resetView();
    //     setOpen(state);
    //   }}
    // >
    //   <DialogTrigger asChild></DialogTrigger>
    //   <DialogContent className="sm:max-w-[800px] max-h-[90vh] overflow-auto">
    <div className="sm:max-w-[800px] max-h-[70vh] overflow-auto">
      <DialogHeader>
        <DialogDescription className="pt-4">
          <p className="text-base">
            To kickstart some ideas, simply pick one of these three questions and add your answer below. It can just be
            a short sentence to express a rough direction or idea you have. Our A.I. Copilot will generate 5 ideas based
            on your answers:
            <ul className="ml-6 mt-4 mb-4">
              <li>- What achievement would make you feel immensely proud of yourself?</li>
              <li>- What problem have you always wanted to solve but didn&apos;t know where to start?</li>
              <li>- Envision yourself in five years - what does a perfect day look like?</li>
            </ul>
          </p>
        </DialogDescription>
        <div className="py-4">
          <Textarea
            className="text-base"
            style={{ minHeight: `150px` }}
            value={textareaValue}
            onChange={(e) => {
              setTextareaValue(e.target.value);
            }}
          />
        </div>
      </DialogHeader>
      <DialogFooter className="mb-2">
        <Button type="submit" onClick={getGoalsAndSaveAspiration} className="text-lg w-full">
          Suggest
        </Button>
        {/* <Button onClick={resetTemplate}>Reset</Button> */}
      </DialogFooter>
      {showResults && lastSubmittedValue === textareaValue && <SuggestGoalResults userInput={textareaValue} />}
    </div>
    //   </DialogContent>
    // </Dialog>
  );
}
