import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "@/providers/AuthProvider";
import { Breadcrumbs } from "@components/Breadcrumbs";
import { Button } from "@shadcn/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@shadcn/dropdown-menu";
import { Skeleton } from "@shadcn/skeleton";
import { useDebouncedCallback } from 'use-debounce';
import { CalendarPlus, Check, MoreHorizontal, Trash2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { v4 as uuid } from "uuid";
import { useFetchGoalByGoalId } from "../../../queries/goals";
import {
  useAddTagsToProjectMutation,
  useCompleteProjectMutation,
  useFetchProjectByProjectId,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} from "../../../queries/projects";
import useSettingsStore from "@/store/settings";
import { AddTimeBlockDialog } from "../../components/AddTimeBlockDialog";
import { Editor } from "../../components/lexical/Editor";
import TimeblockList from "./TimeblockList";
import ContentEditable from "react-contenteditable";
import { useDocumentTitle } from "usehooks-ts";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@shadcn/alert-dialog";

const ProjectView: React.FC = () => {
  const navigate = useNavigate();
  const { isAdmin, loading } = useAuthContext();

  const { activeProject, updateSettings } = useSettingsStore();
  const { projectID: projectIdParams } = useParams<{ projectID: string }>();
  const projectID = projectIdParams || activeProject;

  const { isPending, data } = useFetchProjectByProjectId(projectID);

  const updateProject = useUpdateProjectMutation();
  const completeProject = useCompleteProjectMutation();
  const addTagsToProject = useAddTagsToProjectMutation();
  const deleteProject = useDeleteProjectMutation();

  const [projectName, setProjectName] = useState("");

  const goalId = data?.goalId;
  const { data: goal } = useFetchGoalByGoalId(goalId);

  useEffect(() => {
    if (data) {
      setProjectName(data.name);
      updateSettings({ activeProject: projectID });
    }
  }, [data, updateSettings, projectID]);

  useDocumentTitle(projectName + " - Lifehack App");

  const debouncedUpdateProject = useDebouncedCallback(
    (newName: string) => {
      updateProject.mutate({ id: projectID, name: newName });
    },
    500
  );

  const projectNameRef = useRef<HTMLDivElement | null>(null);
  const currentValueRef = useRef(projectName);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showTags, setShowTags] = useState(false);

  if (loading) return null;
  if (!projectID) return <div>Error</div>;

  const handleProjectNameBlur = () => {
    if (projectNameRef.current) {
      const newName = projectNameRef.current.textContent || "";
      if (newName !== currentValueRef.current) {
        currentValueRef.current = newName;
        setProjectName(newName);
        debouncedUpdateProject(newName);
      }
    }
  };

  const handleProjectNameChange = (event: React.FormEvent<HTMLDivElement>) => {
    const newName = event.currentTarget.textContent || "";
    setProjectName(newName);
    debouncedUpdateProject(newName);
  };

  if (isPending || !data || !goal) {
    return (
      <div className="w-full h-full flex justify-center items-center p-6">
        <div>
          <Skeleton className="h-4 w-[400px] mb-3" />
          <Skeleton className="h-4 w-[240px] mb-3" />
          <Skeleton className="h-4 w-[500px] mb-2" />
          <Skeleton className="h-4 w-[520px] mb-2" />
          <Skeleton className="h-4 w-[550px] mb-2" />
          <Skeleton className="h-4 w-[510px] mb-2" />
          <Skeleton className="h-4 w-[290px]" />
        </div>
      </div>
    );
  }

  const handleCompleteProject = () => {
    completeProject.mutate(projectID);
    updateSettings({ activeProject: "" });
    navigate("/");
  };

  //TODO: Test share and unshare project
  const shareProject = () => {
    if (data?.id) {
      updateProject.mutate({
        id: projectID,
        shareHash: uuid(),
        publicStatus: true,
      });
    }
  };

  const unshareProject = () => {
    if (data?.id) {
      updateProject.mutate({
        id: projectID,
        shareHash: "",
        publicStatus: false,
      });
    }
  };

  const copyUrlToClipboard = async () => {
    if (data?.shareHash) {
      try {
        await navigator.clipboard.writeText(
          `${window.location.origin}/share/${data.shareHash}`
        );
        console.log("URL copied to clipboard");
      } catch (err) {
        console.error("Failed to copy URL: ", err);
      }
    }
  };

  const handleTagsChange = (tags: string[]) => {
    addTagsToProject.mutate({ projectId: projectID, tags: tags });
  };

  const handleDeleteProject = () => {
    deleteProject.mutate(projectID);
    updateSettings({ activeProject: "" });
    if (data?.goalId) {
      navigate(`/goals/${data.goalId}`);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[800px]">
        <div id="project-nav">
          <nav className="w-full py-8 flex justify-between items-center">
            <div className="flex items-center">
              <Breadcrumbs
                pages={[
                  { name: "Northstars", href: "/northstars", current: false },
                  {
                    name: goal.name || "Untitled Goal",
                    href: `/goals/${goal.id}`,
                    current: false,
                  },
                  {
                    name: projectName || "Untitled Project",
                    href: "#",
                    current: true,
                  },
                ]}
              />
            </div>

            <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="px-3 py-2">
                  <MoreHorizontal size={16} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    onClick={handleCompleteProject}
                  >
                    <Check className="mr-1 h-4 w-4" />
                    <span>Complete</span>
                  </DropdownMenuItem>
                  <AlertDialog onOpenChange={(open) => {
                    if (!open) setDropdownOpen(false);
                  }}>
                    <AlertDialogTrigger asChild>
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash2 className="mr-1 h-4 w-4 text-red-600" />
                        <span className="text-red-600">Delete</span>
                      </DropdownMenuItem>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                          This action cannot be undone. This will permanently delete the Initiative
                          and all its data.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                          onClick={handleDeleteProject}
                          className="bg-red-600 hover:bg-red-700"
                        >
                          Delete
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                  {isAdmin && data?.publicStatus && (
                    <>
                      <DropdownMenuItem onSelect={(e) => {
                        e.preventDefault();
                        setShowTags(true);
                      }}>
                        <span>Manage Tags</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={unshareProject}>
                        <span>Unshare</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={copyUrlToClipboard}>
                        <span>Copy Share URL</span>
                      </DropdownMenuItem>
                    </>
                  )}

                  {isAdmin && !data?.publicStatus && (
                    <DropdownMenuItem onClick={shareProject}>
                      <span>Share</span>
                    </DropdownMenuItem>
                  )}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </nav>
        </div>
        <div className="timeblock-container group">
          <div className="flex space-x-4 mb-4 justify-items-stretch">
            <TimeblockList projectId={projectID} />

            <AddTimeBlockDialog projectId={projectID}>
              <Button variant="outline" className="p-5">
                <CalendarPlus size={20} />
              </Button>
            </AddTimeBlockDialog>
          </div>

          <div className="mt-6">
            <ContentEditable
              html={projectName}
              tagName="h1"
              className="w-full font-argent text-3xl md:text-4xl font-bold p-3 focus:outline-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
              innerRef={projectNameRef}
              onChange={handleProjectNameChange}
              onBlur={handleProjectNameBlur}
            />
          </div>
        </div>
        <Editor key={projectID} projectId={projectID} />

        <AlertDialog open={showTags} onOpenChange={(open) => {
          setShowTags(open);
          if (!open) setDropdownOpen(false);
        }}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Manage Tags</AlertDialogTitle>
            </AlertDialogHeader>
            <div className="py-4">
              <TagsInput
                value={data?.tags || []}
                onChange={handleTagsChange}
                name="project-tags"
                placeHolder="Enter tags"
              />
            </div>
            <AlertDialogFooter>
              <AlertDialogCancel>Close</AlertDialogCancel>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </>
  );
};

export default ProjectView;
