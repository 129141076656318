import React from "react";
import { motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LearnIframe } from "./LearnIframe";

interface LearnOverlayProps {
  show: boolean;
  path?: string;
  onClose: () => void;
}

export const LearnOverlay: React.FC<LearnOverlayProps> = ({ show, path, onClose }) => {
  if (!show) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-20 flex justify-center items-center"
      onClick={onClose} // Close iframe when overlay is clicked
    >
      <button
        className="absolute top-0 right-0 mt-4 mr-4 px-2 py-2 bg-primary text-white rounded-full z-30 border-slate-500 border"
        onClick={onClose}
      >
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="relative bg-white py-1 rounded-lg shadow-lg w-11/12 h-[95%]"
        onClick={(e) => e.stopPropagation()} // Prevent click inside the iframe container from closing it
      >
        <LearnIframe path={path} />
      </motion.div>
    </div>
  );
};
