import { ToolInvocation } from "ai"
import { AiToolDocument } from "@shared/types"
import { Avatar, AvatarFallback } from "@shadcn/avatar"

interface AiToolExecutionInfoProps {
  executions: ToolInvocation[]
}

export function AiToolExecutionInfo({ executions }: AiToolExecutionInfoProps) {
  return (
    <div className="ai-tool-executions">
      {executions.map((execution, index) => (
        <div key={index} className="ai-tool-execution">
          {execution.toolName === "exa" && (execution.state === "partial-call" || execution.state === "call") && (
            <span className="text-sm text-gray-500">Searching knowledge online...</span>
          )}
          {execution.toolName === "internal-rag" && (execution.state === "partial-call" || execution.state === "call") && (
            <span className="text-sm text-gray-500">Searching Lifehack knowledge...</span>
          )}
          { execution.state === "result" && (
            <span className="text-sm text-gray-500">Found relevant results. Processing...</span>
          )}
          {execution.state === "result" && (
            <div className="py-2 text-sm text-gray-600">
              <div className="space-y-2">
                {JSON.parse(execution.result).map((item: AiToolDocument, idx: number) => (  
                  <a
                    key={idx}
                    href={item.metadata.source}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center p-2 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors cursor-pointer"
                  >
                    <Avatar className="w-4 h-4 mr-2 flex-shrink-0">
                      <AvatarFallback className="bg-slate-200">
                        {new URL(item.metadata.source).hostname.split('.').slice(-2, -1)[0].charAt(0).toUpperCase()}
                      </AvatarFallback>
                    </Avatar>
                    <p className="text-xs text-gray-600 font-medium truncate">
                      {item.metadata.title}
                    </p>
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}