import { useState } from "react";
import { Link } from "react-router-dom";
import { Project } from "@shared/types";
import { usefetchAllPublicProjects } from "../../../queries/projects";
import { ProjectCard } from "./ProjectCard";

function TemplatesLibraryView() {
  // const [projects, setProjects] = useState<Project[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { data: projects } = usefetchAllPublicProjects();

  // useEffect(() => {
  //   // This function fetches the projects and updates the state with the results
  //   async function loadProjects() {
  //     const result = await fetchAllSharedProjects();
  //     setProjects(result);
  //   }

  //   loadProjects();
  // }, []); // The empty dependency array ensures this effect runs once when the component mounts

  const filteredProjects =
    projects &&
    projects.filter(
      (project: Project) =>
        project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (project.details && project.details.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (project.tags && project.tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase())))
    );

  const handleKeywordClick = (keyword: string) => {
    setSearchQuery(keyword);
  };

  const distinctTagsWithCount: Record<string, number> =
    projects?.reduce((acc: Record<string, number>, project: Project) => {
      if (project.tags) {
        project.tags.forEach((tag) => {
          if (!acc[tag]) {
            acc[tag] = 1;
          } else {
            acc[tag]++;
          }
        });
      }
      return acc;
    }, {}) || {};

  const sortedTagsWithCount: Record<string, number> = Object.fromEntries(
    Object.entries(distinctTagsWithCount).sort(([, a], [, b]) => b - a)
  );

  return (
    <div className="container max-w-[1024px] pt-10">
      <h1 className="text-4xl font-argent mb-4">Initiative Templates for Your North Star</h1>
      <p className="text-slate-600 max-w-[800px]">
        Discover our Action Recipe Library – your key to efficiency and progress. This curated collection offers
        customizable templates specifically designed to help you create and manage initiatives for your North Star
        goals. Explore, adapt, and implement these step-by-step strategies to easily turn your aspirations into
        actionable plans and achievements.
      </p>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search Action Recipes..."
        className="w-[50%] p-2 border border-gray-300 rounded my-9"
      />
      <div className="flex flex-wrap space-x-2 mb-10 text-sm">
        {sortedTagsWithCount &&
          Object.keys(sortedTagsWithCount)
            .slice(0, 8)
            .map((tag) => (
              <Link
                key={`${tag}`}
                to="#"
                onClick={() => handleKeywordClick(tag)}
                className="text-primary hover:underline"
              >
                {tag}
              </Link>
            ))}
      </div>
      <div className="grid grid-cols-1 gap-4 mt-6 lg:grid-cols-2 xl:grid-cols-3">
        {filteredProjects &&
          filteredProjects.map((project: Project) => <ProjectCard key={project.id} project={project} />)}
      </div>
    </div>
  );
}

export default TemplatesLibraryView;
