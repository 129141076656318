import ContentLoader from "react-content-loader";
interface AnswerListLoaderProps {
  className?: string;
}
export const AnswerListLoader: React.FC<AnswerListLoaderProps> = (props) => (
  <ContentLoader
    speed={2}
    width={600}
    height={150}
    viewBox="0 0 600 150"
    backgroundColor="#ffffff"
    foregroundColor="#ecebeb"
    {...props}

  >
    <rect x="6" y="38" rx="0" ry="0" width="100" height="61" />
    <rect x="145" y="20" rx="0" ry="0" width="45" height="24" />
    <rect x="211" y="20" rx="0" ry="0" width="480" height="24" />
    <rect x="146" y="59" rx="0" ry="0" width="45" height="24" />
    <rect x="211" y="59" rx="0" ry="0" width="480" height="24" />
    <rect x="146" y="101" rx="0" ry="0" width="45" height="24" />
    <rect x="212" y="101" rx="0" ry="0" width="480" height="24" />
  </ContentLoader>
);
