import { Plus } from "lucide-react";
import React from "react";
import "react-day-picker/dist/style.css";

import { Button } from "@shadcn/button";
import { useState } from "react";

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@shadcn/dialog";

import WarningBox from "../WarningBox";

import { useAddTimeblockMutation } from "../../../queries/timeblocks";

import { HelpFooterText } from "../HelpFooterText";
import { CalendarPopover } from "./CalendarPopover";
import { DurationPopover } from "./DurationPopover";
import TimeBlockTypeToggle from "./TimeBlockTypeToggle";
import { TimePopover } from "./TimePopover";

import { useAuthContext } from "@/providers/AuthProvider";
import { usePostHog } from "posthog-js/react";

interface AddTimeBlockDialogProps {
  projectId?: string;
  children: React.ReactNode;
}

interface TimeblockOptions {
  date: Date;
  startTime: number;
  duration: number;
  repeat: string;
}

export function AddTimeBlockDialog({ projectId, children }: AddTimeBlockDialogProps) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
  // const [isHourSelectOpen, setHourSelectOpen] = useState(false);
  
  const addTimeblock = useAddTimeblockMutation();
  const posthog = usePostHog();
  const { user, loading } = useAuthContext();

  const now = new Date();
  let initialHour = now.getMinutes() > 0 ? now.getHours() + 1 : now.getHours();
  initialHour = initialHour % 24;

  const defaultTimeblockOptions: TimeblockOptions = {
    date: new Date(),
    startTime: initialHour,
    duration: 1,
    repeat: "",
  };

  console.log("startTime", initialHour);

  const [timeblockOptions, setTimeblockOptions] = useState<TimeblockOptions>(defaultTimeblockOptions);
  const [timeblock, setTimeblock] = useState({
    adhocName: "",
    projectId: projectId,
    type: "project",
  });

  if (loading) return null;
  if (!user) return null;

  const uid = user.uid;

  const handleDateChange = (newDate: Date) => {
    setTimeblockOptions((prevState) => ({
      ...prevState,
      date: newDate,
    }));
  };

  const handleStartTimeChange = (newStartTime: number) => {
    setTimeblockOptions((prevState) => ({
      ...prevState,
      startTime: newStartTime,
    }));
  };

  const handleDurationChange = (newDuration: number) => {
    setTimeblockOptions((prevState) => ({
      ...prevState,
      duration: newDuration,
    }));
  };

  // const handleRepeatChange = (newRepeat: string) => {
  //   setTimeblockOptions((prevState) => ({
  //     ...prevState,
  //     repeat: newRepeat,
  //   }));
  // };

  function getTimeblockType(type: string): "project" | "adhoc" | undefined {
    if (type === "project" || type === "adhoc") {
      return type;
    }
    return undefined; // or return "project" as a default, based on your needs
  }

  function combineDateandHour(date: Date, hour: number): Date {
    const newDate = new Date(date);
    newDate.setHours(hour);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    return newDate;
  }

  const handleDialogAdd = () => {
    const eventProperties: { timeblock_type: string; projectId?: string } = { timeblock_type: timeblock.type };
    if (timeblock.projectId) {
      eventProperties.projectId = timeblock.projectId;
    }
    posthog?.capture("app_timeblock_added", eventProperties);

    if (timeblock.type === "adhoc" && timeblock.adhocName) {
      addTimeblock.mutate({
        startTime: combineDateandHour(timeblockOptions.date, timeblockOptions.startTime),
        duration: timeblockOptions.duration,
        userId: uid,
        type: getTimeblockType(timeblock.type),
        ...(timeblock.adhocName ? { adhocName: timeblock.adhocName } : {}),
      });
    } else {
      addTimeblock.mutate({
        startTime: combineDateandHour(timeblockOptions.date, timeblockOptions.startTime),
        duration: timeblockOptions.duration,
        projectId: timeblock.projectId,
        userId: uid,
        type: getTimeblockType(timeblock.type) || "project",
      });
    }

    // Reset state after adding the time block
    // FIXME: have to reset here because onOpenChange is not being called
    setTimeblockOptions(defaultTimeblockOptions);

    setAlertDialogOpen(false);
  };

  function handleTypeChange(newType: "project" | "adhoc") {
    setTimeblock((prev) => ({ ...prev, type: newType }));
  }

  function handleAdhocNameChange(newAdhocName: string) {
    setTimeblock((prev) => ({ ...prev, adhocName: newAdhocName }));
  }

  function handleProjectIdChange(newProjectId: string) {
    setTimeblock((prev) => ({ ...prev, projectId: newProjectId }));
  }

  return (
    <>
      <Dialog
        open={isAlertDialogOpen}
        onOpenChange={(isOpen) => {
          setAlertDialogOpen(isOpen);
          if (!isOpen) {
            // The dialog box is being closed. Reset the settings.
            // FIXME: this is not called when pressed "Add Timeblock" button
            setTimeout(() => {
              setTimeblockOptions(defaultTimeblockOptions);
              setErrorMsg(null);
            }, 0);
          }
        }}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent className="overflow-y-auto max-h-full max-w-[800px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">Create a Focus Block</DialogTitle>
          </DialogHeader>
          {errorMsg && (
            <WarningBox subject="Start Time Missing" errorMessage={errorMsg} className="bg-red-200 text-black" />
          )}
          <div className="ml-4">
            <TimeBlockTypeToggle
              defaultProjectId={projectId}
              onTypeChange={handleTypeChange}
              onAdhocNameChange={handleAdhocNameChange}
              onProjectIdChange={handleProjectIdChange}
            />
          </div>
          <div className="flex items-start ml-4 space-x-4">
            <CalendarPopover onDateChange={handleDateChange} />
            <TimePopover onStartTimeChange={handleStartTimeChange} />
            <DurationPopover onDurationChange={handleDurationChange} />
            {/* <RepeatPopover onOptionChange={handleRepeatChange} /> */}
          </div>

          <DialogFooter>
            <Button onClick={handleDialogAdd} className="w-full text-bold text-white">
              <Plus size={16} className="mr-1" />
              Create
            </Button>
          </DialogFooter>
          <HelpFooterText
            url="https://help.lifehack.org/article/118-what-are-focus-blocks"
            text="Learn about Focus Block"
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
