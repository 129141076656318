import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

type Theme = "dark" | "light";

interface SettingsState {
  activeTab: string;
  activeGoal: string;
  activeProject: string;
  theme: Theme;
  language: string;
  sidebarHidden: boolean;
  updateSettings: (settings: Partial<SettingsState>) => void;
  toggleSidebarHidden: () => void;
}

const useSettingsStore = create<SettingsState>()(
  devtools(
    persist(
      (set) => ({
        activeTab: "goals",
        activeGoal: "",
        activeProject: "",
        theme: "light",
        language: "en",
        sidebarHidden: false,
        updateSettings: (settings) => set((state) => ({ ...state, ...settings })),
        toggleSidebarHidden: () => set((state) => ({ sidebarHidden: !state.sidebarHidden })),
      }),
      {
        name: "settings-storage",
      }
    )
  )
);

export default useSettingsStore;
