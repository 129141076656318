import { ChevronRight } from "lucide-react";
import React from "react";
import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@shadcn/command";

import { useNavigate } from "react-router-dom";
import { useFetchGoals } from "../../../queries/goals";
import { AddGoalButtonDialog } from "../AddDialogs/AddGoalButtonDialog";
import { AddProjectButtonDialog } from "../AddDialogs/AddProjectButtonDialog";
import useSettingsStore from "@/store/settings";

interface CommandMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function CommandMenu({ open, setOpen }: CommandMenuProps) {
  const [goalDialogOpen, setGoalDialogOpen] = React.useState(false);
  const [projectDialogOpen, setProjectDialogOpen] = React.useState(false);
  // const [situationDialogOpen, setSituationDialogOpen] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && e.metaKey) {
        setOpen(!open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [open, setOpen]);

  const activeGoalId = useSettingsStore((state) => state.activeGoal);
  const { data: goals } = useFetchGoals();

  if (!goals) return null;

  const activeGoal = goals.find((goal) => goal.id === activeGoalId);
  const activeGoalName = activeGoal ? activeGoal.name : "No Active Goal";

  return (
    <>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Quick Commands" />
        <CommandList className="p-1 max-h-[400px]">
          <CommandEmpty>No commands found.</CommandEmpty>
          <CommandGroup heading="Add">
            <CommandItem
              className="mb-1 cursor-pointer"
              onSelect={() => {
                setOpen(false);
                setGoalDialogOpen(true);
              }}
            >
              <ChevronRight className="h-4 w-4 mr-1" />
              Create a new Northstar
            </CommandItem>
            <CommandItem
              className="mb-1 cursor-pointer"
              onSelect={() => {
                setOpen(false);
                setProjectDialogOpen(true);
              }}
            >
              <ChevronRight className="h-4 w-4 mr-1" />
              Create a new Initiative in {activeGoalName}
            </CommandItem>
          </CommandGroup>
          <CommandGroup heading="Jump to">
            {goals.map((goal) => (
              <CommandItem
                key={goal.id}
                className="mb-1 cursor-pointer"
                onSelect={() => {
                  setOpen(false);
                  navigate(`/goals/${goal.id}`);
                }}
              >
                <ChevronRight className="h-4 w-4 mr-1" />
                {goal.name}
              </CommandItem>
            ))}
          </CommandGroup>
          {/* <CommandItem className="mb-1 bg-gray-100 text-gray-500 cursor-not-allowed">
            <ChevronRight className="h-4 w-4 mr-1" />I am in a situation. Please help me reclaim my time.
          </CommandItem> */}
          {/* <CommandItem className="mb-1 cursor-pointer" onSelect={() => { setOpen(false); setSituationDialogOpen(true); }}>
          <ChevronRight className="h-4 w-4 mr-1" />
          I am in a situation. Please help me reclaim my time. 
        </CommandItem> */}
          {/* <CommandItem className="mb-1 bg-gray-100 text-gray-500 cursor-not-allowed">
            <ChevronRight className="h-4 w-4 mr-1" />
            Provide a progress summary towards "{activeGoalName}".
            <CommandShortcut>(Coming Soon)</CommandShortcut>
          </CommandItem>
          <CommandItem className="mb-1 bg-gray-100 text-gray-500 cursor-not-allowed">
            <ChevronRight className="h-4 w-4 mr-1" />
            Suggest a time of Focus Block for "{activeGoalName}".
            <CommandShortcut>(Coming Soon)</CommandShortcut>
          </CommandItem>
          <CommandItem className="mb-1 bg-gray-100 text-gray-500 cursor-not-allowed">
            <ChevronRight className="h-4 w-4 mr-1" />
            Give me an affirmation on "{activeGoalName}".
            <CommandShortcut>(Coming Soon)</CommandShortcut>
          </CommandItem> */}
        </CommandList>
      </CommandDialog>
      {goalDialogOpen && <AddGoalButtonDialog open={goalDialogOpen} setOpen={setGoalDialogOpen} />}
      {projectDialogOpen && (
        <AddProjectButtonDialog open={projectDialogOpen} setOpen={setProjectDialogOpen} goalId={activeGoalId} />
      )}
      {/* <SuggestGoalButtonDialog open={goalDialogOpen} setOpen={setGoalDialogOpen}/> */}
      {/* <SuggestProjectButtonDialog open={projectDialogOpen} setOpen={setProjectDialogOpen}/> */}
      {/* <SuggestSituationTranscriptButtonDialog open={situationDialogOpen} setOpen={setSituationDialogOpen}/> */}
    </>
  );
}
