import { useFetchPublicProjectByShareHash } from "@/queries/projects";
import lifehackLogo from "@/images/lifehack-logo-black.png";

import "./style.css";
import { useHeadlessToHtml } from "./useHeadlessToHtml";

interface TemplateViewProps {
  shareHash: string;
}

const TemplateView: React.FC<TemplateViewProps> = ({ shareHash }: TemplateViewProps) => {
  const { data: project, isPending, isError, error } = useFetchPublicProjectByShareHash(shareHash);
  const html = useHeadlessToHtml(project?.details);

  if (isPending && !html && !project) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const projectName = project?.name ?? "Untitled";
  const finalHtml = html ?? "<p>No content</p>";

  return (
    <>
      <div className="w-[720px] border rounded-md bg-white shadow-md mb-8">
        <div id="template" className="p-10">
          <h1 className="pdf-main-title font-argent text-4xl border-b">{projectName}</h1>
          <div id="template-preview" className="mt-10" dangerouslySetInnerHTML={{ __html: finalHtml }} />
        </div>
      </div>
      <div id="template-pdf" className="w-[596px] hidden" style={{ letterSpacing: "0.01px" }}>
        <div className="p-6 bg-lhyellow">
          <img src={lifehackLogo} className="mb-1 w-16" />
          <h1 className="font-argent text-4xl lhgreen">Action Recipe</h1>
        </div>
        <div className="p-6">
          <div
            id="template-preview"
            style={{ letterSpacing: "0.01px" }}
            dangerouslySetInnerHTML={{ __html: finalHtml }}
          />
        </div>
      </div>
    </>
  );
};

export default TemplateView;
