import { ArrowLeftCircle } from "lucide-react";
import { Button } from "@shadcn/button";
import { Card, CardContent, CardHeader, CardTitle } from "@shadcn/card";
import { useFetchCompletedProjectsByGoalId, useUncompleteProjectMutation } from "../../../queries/projects";

import emptyStateCompletedInitiatives from "../../../images/empty/completed-initiatives.png";

interface CompletedProjectsProps {
  goalId: string;
}

function CompletedProjects({ goalId }: CompletedProjectsProps) {
  const { isPending, isError, data, error } = useFetchCompletedProjectsByGoalId(goalId);
  const uncompleteProjectMutation = useUncompleteProjectMutation();

  if (isPending || !data) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  if (data.length === 0) {
    return (
      <div className="flex items-start justify-center h-full">
        <div className="w-full text-center">
          <img src={emptyStateCompletedInitiatives} className="mx-auto mt-4 mb-4 w-64" />
          <div className="font-bold text-xl">You don't have any completed Initiatives</div>
          <div className="text-gray-500 mt-2">
            Once you complete one, you'll see them listed here.{" "}
            <a
              href="https://help.lifehack.org/article/117-how-do-i-complete-an-initiative"
              target="_blank"
              className="text-gray-500 underline"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="completedProjects" className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
      {data.map((project) => (
        <Card key={project.id} className="bg-gray-50">
          <CardHeader>
            <CardTitle className="leading-snug text-lg text-gray-600 font-argent md:text-xl md:leading-normal">
              {project.name}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Button
              onClick={(e) => {
                uncompleteProjectMutation.mutate(project.id);
                e.stopPropagation();
              }}
              variant="outline"
              className="w-full"
            >
              <ArrowLeftCircle size={16} className="mr-2" />
              Move to Active
            </Button>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

export default CompletedProjects;
