import { ArrowUpRight } from "lucide-react";

interface HelpFooterTextProps {
  url: string;
  text: string;
}

export const HelpFooterText = ({ url, text }: HelpFooterTextProps) => {
  return (
    <div className="text-sm mt-4">
      <a href={url} target="_blank" rel="noreferrer">
        {text}
        <ArrowUpRight size={14} className="inline ml-2" />
      </a>
    </div>
  );
};

export default HelpFooterText;