import React, { createContext, useContext, useMemo, useCallback, useRef } from "react";
import { Axiom } from "@axiomhq/js";
import { ReactNode } from "react";
import { getEnv } from "@/utils/getEnv";

type LogType = string;
type LogAction = string;

interface LoggerContext {
  axiom: Axiom | null;
  log: (logType: LogType, action: LogAction, data: Record<string, any>) => Promise<void>;
}

const defaultLogger: LoggerContext = {
  axiom: null,
  log: async () => {
    console.warn("Axiom logger is not initialized. Logging to console instead.");
  },
};

export const LoggerContext = createContext<LoggerContext>(defaultLogger);

export const useLoggerContext = () => useContext(LoggerContext);

interface LoggerProviderProps {
  children: ReactNode;
}

export const LoggerProvider: React.FC<LoggerProviderProps> = ({ children }) => {
  const axiom = useMemo(() => {
    const token = process.env.AXIOM_TOKEN;
    const orgId = process.env.AXIOM_ORG_ID;
    if (!token || !orgId) {
      console.warn("AXIOM_TOKEN and AXIOM_ORG_ID are not set. Axiom logger will not be initialized.");
      return null;
    }
    return new Axiom({ token, orgId });
  }, []);

  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const flush = useCallback(() => {
    if (axiom) {
      axiom.flush();
    }
  }, [axiom]);

  const debouncedFlush = useCallback(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(flush, 3000);
  }, [flush]);

  const log = useCallback(async (logType: LogType, action: LogAction, data: Record<string, any>) => {
    if (!axiom) {
      console.warn("Axiom logger is not initialized. Logging to console instead.");
      console.log({ logType, action, data });
      return;
    }

    await axiom.ingest("app", { logType, action, env: getEnv(), data });
    debouncedFlush();
  }, [axiom, debouncedFlush]);

  const logger = useMemo(() => ({ axiom, log }), [axiom, log]);

  return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>;
};