import { SuggestGoalButtonDialog } from "@/web/pages/AddDialogs/SuggestGoalDialog";
import { Alert, AlertDescription } from "@shadcn/alert";
import { Button } from "@shadcn/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@shadcn/dialog";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@shadcn/hover-card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@shadcn/tabs";
import { Info, Lightbulb, Plus, Sparkle } from "lucide-react";
import { ChangeEvent, useEffect, useState } from "react";

import { useAddGoalMutation } from "@/queries/goals";
import { Input } from "@shadcn/input";
import { Label } from "@shadcn/label";
import { usePostHog } from "posthog-js/react";
import { useNavigate } from "react-router-dom";

interface AddGoalButtonDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
}
export function AddGoalButtonDialog({ open, setOpen, children }: AddGoalButtonDialogProps) {
  const addGoal = useAddGoalMutation();

  const navigate = useNavigate();

  const [goalName, setGoalName] = useState("");

  const posthog = usePostHog();

  const handleGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGoalName(event.target.value);
  };

  const handleCreateGoal = () => {
    posthog?.capture("app_goal_added");
    addGoal.mutate({ name: goalName });
  };

  useEffect(() => {
    if (addGoal.isSuccess) {
      setGoalName(""); // Clear input after goal creation
      setOpen(false);
      navigate(`/goals/${addGoal.data?.id}`);
    }
  }, [addGoal.isSuccess, addGoal.data?.id, navigate, setOpen]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[700px] max-h-[100vh]">
        <DialogTitle className="text-2xl font-bold">Create a Northstar</DialogTitle>
        <Tabs defaultValue="own">
          <TabsList className="w-[100%]">
            <TabsTrigger value="own" className="w-[50%]">
              Create Your Own
            </TabsTrigger>
            <TabsTrigger value="ai" className="w-[50%]">
              <Sparkle size={14} /> AI Assisted
            </TabsTrigger>
          </TabsList>
          <TabsContent value="own">
            <DialogHeader>
              <DialogDescription className="text-base p-4">
                Major life scenarios, stages, or landmarks that you really want to achieve.{" "}
                <a
                  href="https://help.lifehack.org/article/114-what-is-a-northstar"
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </DialogDescription>
            </DialogHeader>
            <form
              onSubmit={(event) => {
                event.preventDefault(); // Prevent form from causing a page refresh
                handleCreateGoal();
              }}
            >
              <div className="grid gap-4 mb-4">
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="goalName" className="text-right leading-tight">
                    Northstar Name
                  </Label>
                  <Input
                    id="goalName"
                    value={goalName}
                    onChange={handleGoalNameChange}
                    autoFocus
                    className="col-span-3"
                    autoComplete="off"
                    placeholder="Enter a name e.g. Improve my Focus"
                  />
                </div>
                <HoverCard>
                  <HoverCardTrigger className="-mt-3">
                    <p className="cursor-help underline font-medium text-xs text-gray-500 p-1 text-right">
                      <Lightbulb size={12} className="inline mr-1 align-middle" />
                      Need some suggestions?
                    </p>
                  </HoverCardTrigger>
                  <HoverCardContent>
                    <p className="text-sm font-bold text-gray-400 mb-2">Feel free to copy &amp; paste:</p>
                    <ul className="text-sm font-medium italic">
                      <li className="mb-2 leading-tight">Improve my focus</li>
                      <li className="mb-2 leading-tight">Prioritize self-care through daily rituals and routines</li>
                      <li className="mb-2 leading-tight">Learn a new language</li>
                      <li className="mb-2 leading-tight">Improve my health</li>
                      <li className="mb-2 leading-tight">Foster closer relationships with my family</li>
                    </ul>
                  </HoverCardContent>
                </HoverCard>
              </div>
              <DialogFooter>
                <Button type="submit" className="w-full font-bold">
                  <Plus size={16} className="mr-1" /> Create
                </Button>
              </DialogFooter>
              <Alert className="mb-4 border-none text-slate-600">
                <Info className="h-4 w-4" />
                <AlertDescription>
                  Don&apos;t worry if your Northstar Name isn&apos;t perfect right now. It&apos;s more important to get
                  started!
                </AlertDescription>
              </Alert>
              {/* <Collapsible className="w-full font-medium text-left hover:bg-gray-200">
                <CollapsibleTrigger className="block w-full">
                  <PlayCircle size="16" className="inline align-middle mr-1" /> How to Create a Northstar
                </CollapsibleTrigger>
                <CollapsibleContent className="mt-2">
                  <VimeoVideoEmbed videoId="852279950" title="Time Flow System - North Stars" />
                </CollapsibleContent>
              </Collapsible> */}
            </form>
          </TabsContent>
          <TabsContent value="ai">
            <SuggestGoalButtonDialog />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}
