import { FolderOpenIcon } from "lucide-react";
import CreateInitiativePopover from "../../../components/CreateInitiativePopover";

interface NoProjectProps {
  goalId: string;
}

export function NoProject({ goalId }: NoProjectProps) {
  return (
    <div className="grid gap-4">
      <div className="flex flex-col items-center justify-center py-8">
        <FolderOpenIcon className="w-12 h-12 text-muted-foreground" />
        <h3 className="text-lg font-semibold">No initiatives yet</h3>
        <p className="text-sm text-muted-foreground">You haven&apos;t added any initiatives for this Northstar.</p>
        <div className="mt-4">{goalId && <CreateInitiativePopover goalId={goalId} />}</div>
      </div>
    </div>
  );
}