import * as Sentry from "@sentry/react";

// if (process.env.NODE_ENV !== "development"){
Sentry.init({
  dsn: "https://5e33d540397dea64e9e1bad0aa61b43e@o159890.ingest.sentry.io/4505676303040512",
  environment: process.env.REACT_APP_ENV,
  integrations: [Sentry.replayIntegration()],
  // debug: true,
  tracePropagationTargets: ["localhost", "time-block-561ce.web.app", "app.lifehack.org", /^\//],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// }
