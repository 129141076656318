import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Prompt } from "@shared/types";

interface Report {
  [key: string]: string;
}

export interface Answers {
  [key: string]: string;
}

interface OnboardingState {
  step: number;
  assessmentData: Prompt | null;
  answers: Answers | null;
  northstar: Northstar | null;
  report: Report | null;
  setStep: (step: number) => void;
  setAssessmentData: (assessmentData: Prompt) => void;
  setAnswers: (answers: Answers) => void;
  setNorthstar: (northstar: Northstar) => void;
  setReport: (report: Report) => void;
}

interface Northstar {
  goal: string;
  description: string;
}

export const useOnboardingStore = create<OnboardingState>()(
  devtools(
    (set) => ({
      step: 1,
      assessmentData: {} as Prompt,
      northstar: null,
      report: null,
      answers: null,
      setStep: (step) => set(() => ({ step })),
      setAssessmentData: (assessmentData) => set({ assessmentData }),
      setAnswers: (answers) => set({ answers }),
      setNorthstar: (northstar) => set(() => ({ northstar })),
      setReport: (report) => set(() => ({ report })),
    }),
    {
      name: "OnboardingStore",
    }
  )
);
