import { useState, useEffect } from "react";
import { Button } from "@shadcn/button";
import { PromptAnswer } from "@shared/types";
import { Back } from "@components/Back";
import { AiSection } from "./AiCoach/AiSection";
import LinkedGoal from "./LinkedGoal";
import { useFetchGoalByGoalId } from "@/queries/goals";

interface AnswerViewModeProps {
  form: PromptAnswer; // replace FormType with the actual type of your form
  handleEdit: () => void;
  toggleEditMode: () => void;
}

// TODO: add toggleEditMode back in, when the Ai Response need it to trigger edit mode
export const AnswerViewMode = ({ form, handleEdit }: AnswerViewModeProps) => {
  // Inside your component
  const [dateString, setDateString] = useState("");
  const { data: goal } = useFetchGoalByGoalId(form.goalId);

  useEffect(() => {
    if (form.updatedAt) {
      const date: Date = form.updatedAt.toDate();
      const options: Intl.DateTimeFormatOptions = {
        weekday: "short",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      setDateString(date.toLocaleString("en-US", options));
    }
  }, [form.updatedAt]);

  return (
    <div className="my-10">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Back link="/prompts" text="Your Prompts" className="pt-4" />
        </div>
        <Button onClick={handleEdit}>Edit</Button>
      </div>
      <div className="flex justify-between items-center">
        <h1 className="text-4xl mt-2 mb-2 text-primary">{form.title}</h1>
        <span className="text-gray-400">{dateString}</span>
      </div>
      <p className="text-gray-500">{form.description}</p>
      {goal && <LinkedGoal name={goal.name} href={`/goals/${goal.id}`} />}
      {form.questions.map((question, index) => (
        <div key={`${question.question}-${index}`}>
          <h2 className="text-2xl mt-10 mb-1">{question.question}</h2>
          {question.answer.split("\n").map((line, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <p key={index}>{line}</p>
          ))}
        </div>
      ))}
      <AiSection promptAnswer={form} />
      {/* <PromptAiCoach promptAnswer={form} toggleEditMode={toggleEditMode} /> */}
    </div>
  );
};
