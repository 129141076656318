import { useState, useRef, useEffect } from "react";
import { Clock } from "lucide-react";
import { Button } from "@shadcn/button";
import { Popover, PopoverContent, PopoverTrigger } from "@shadcn/popover";
import { OptionButton } from "./OptionButton";

type TimePopoverProps = {
  onStartTimeChange: (time: number) => void;
};

export const TimePopover = ({ onStartTimeChange }: TimePopoverProps) => {
  const now = new Date();
  let initialHour = now.getMinutes() > 0 ? now.getHours() + 1 : now.getHours();
  initialHour = initialHour % 24;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState<number>(initialHour);
  const hours = Array.from(Array(24).keys());
  const hourButtonRef = useRef<HTMLButtonElement[]>([]);

  const handleHourButtonClick = (hour: number) => {
    setSelectedHour(hour);
    onStartTimeChange(hour);
    setIsOpen(false);
  };

  useEffect(() => {
    // Populating the hourButtonRef array after rendering the hour buttons
    hourButtonRef.current = hourButtonRef.current.slice(0, hours.length);
  }, [hours]);

  return (
    <Popover open={isOpen} onOpenChange={(state) => setIsOpen(state)}>
      <PopoverTrigger>
        <OptionButton>
          <Clock className="inline-block" />
          <span>{selectedHour.toString().padStart(2, "0")}:00</span>
        </OptionButton>
      </PopoverTrigger>
      <PopoverContent className="w-auto" align="start">
        <a href="#" className="col-span-2 text-center text-gray-500">
          :00
        </a>
        <a href="#" className="col-span-2 text-center text-gray-500">
          :15
        </a>
        <a href="#" className="col-span-2 text-center text-gray-500">
          :30
        </a>
        <a href="#" className="col-span-2 text-center text-gray-500">
          :45
        </a>
        <div className="grid grid-cols-6 gap-2 mt-4">
          {hours.map((hour) => (
            <Button
              key={hour}
              className=""
              variant={selectedHour === hour ? "default" : "outline"}
              onClick={() => handleHourButtonClick(hour)}
              ref={(button) => {
                hourButtonRef.current[hour] = button as HTMLButtonElement;
              }}
              data-hour={hour.toString()}
            >
              {`${hour.toString().padStart(2, "0")}:00`}
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
