import { ArrowUpRight, Star } from "lucide-react";

interface LinkedGoalProps {
  name: string;
  href: string;
}

export default function LinkedGoal({ name, href }: LinkedGoalProps) {
  return (
    <div className="mt-8 rounded-lg overflow-hidden shadow-sm">
      <div className="p-4 bg-gradient-to-br from-brand-secondary/60 to-brand-secondary/40">
        <h3 className="text-sm mb-2 flex items-center text-slate-500">
          <Star className="mr-1 h-5 w-5 text-slate-500" />
          This Growth Prompt is linked to a North Star
        </h3>
        <a
          href={href}
          className="text-semibold inline-flex items-center text-primary hover:text-primary/80 transition-colors font-medium"
        >
          <span>{name}</span>
          <ArrowUpRight className="ml-1 h-4 w-4" />
        </a>
      </div>
    </div>
  );
}
