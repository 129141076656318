import { ChangeEvent, useState } from "react";
import { Info, Lightbulb, Plus } from "lucide-react";
import { Button } from "@shadcn/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@shadcn/dialog";
import { Input } from "@shadcn/input";
import { Label } from "@shadcn/label";

import { Alert, AlertDescription } from "@shadcn/alert";

import { useAddProjectMutation } from "@/queries/projects";
import { usePostHog } from "posthog-js/react";

interface AddProjectButtonDialogProps {
  goalId: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  children?: React.ReactNode;
}

export function AddProjectButtonDialog({ goalId, open: controlledOpen, setOpen: controlledSetOpen, children }: AddProjectButtonDialogProps) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
  const open = controlledOpen !== undefined ? controlledOpen : uncontrolledOpen;
  const setOpen = controlledSetOpen !== undefined ? controlledSetOpen : setUncontrolledOpen;

  const addProjectMutation = useAddProjectMutation();
  const [projectName, setProjectName] = useState("");
  const posthog = usePostHog();

  const handleProjectNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const handleCreateProject = () => {
    posthog?.capture("app_project_added", { goal_id: goalId });
    addProjectMutation.mutate({ name: projectName, goalId });
    setProjectName(""); // Clear input after project creation
    setOpen(false);
  };

  const handleOpenChange = (state: boolean) => {
    if (!state) {
      setProjectName(""); // Clear input after project creation
    }
    setOpen(state);
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[700px] max-h-[100vh]">
        <DialogHeader>
          <DialogTitle className="text-2xl">Create a New Initiative</DialogTitle>
        </DialogHeader>
        <DialogDescription className="text-base p-4">
          Like Projects, these are your engines of progress. They focus on a specific milestone to set forth plans
          and actions for achieving an objective.{" "}
          <a
            href="https://help.lifehack.org/article/116-what-is-an-initiative"
            className="underline mt-2"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        </DialogDescription>
        <form
          onSubmit={(event) => {
            event.preventDefault(); // Prevent form from causing a page refresh
            handleCreateProject();
          }}
        >
          <div className="grid gap-4 mb-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="projectName" className="text-right text-sm leading-tight">
                Initiative Name
              </Label>
              <Input
                id="projectName"
                value={projectName}
                onChange={handleProjectNameChange}
                autoFocus
                className="col-span-3"
                autoComplete="off"
                placeholder="Enter a name e.g. Meditate Daily"
              />
            </div>
            <a
              onClick={() => setOpen(true)}
              className="cursor-help underline font-medium text-xs text-gray-500 p-1 text-right"
            >
              <Lightbulb size={12} className="inline mr-1 align-middle" />
              Need help? Ask your AI Copilot
            </a>
          </div>

          <DialogFooter>
            <Button type="submit" className="w-full font-bold">
              <Plus size={16} className="mr-1" /> Create
            </Button>
          </DialogFooter>
          <Alert className="mb-4 border-none text-slate-600">
            <Info className="h-4 w-4" />
            <AlertDescription>
              Just think about what are some potential projects you could do that would contribute to your
              Northstar?
            </AlertDescription>
          </Alert>
        </form>
      </DialogContent>
    </Dialog>
  );
}
