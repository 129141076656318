import useSettingsStore from "@/store/settings";
import { Back } from "@components/Back";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@shadcn/dialog";
import { Skeleton } from "@shadcn/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@shadcn/tabs";
import { PlayCircle } from "lucide-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import emptyStateDashboard from "../../../images/empty/add-northstar.png";
import { useFetchGoals } from "../../../queries/goals";
import CreateInitiativePopover from "../../components/CreateInitiativePopover";
import VimeoVideoEmbed from "../../components/VimeoVideoEmbed";
import ActiveProjects from "./ActiveProjects";
import CompletedProjects from "./CompletedProjects";
import GoalSection from "./GoalSection";

const GoalView: React.FC = () => {
  const { data: goals, isPending } = useFetchGoals();
  const { activeGoal, updateSettings } = useSettingsStore();
  const { goalId: goalIdParam } = useParams<{ goalId?: string }>();
  const goalId = goalIdParam || activeGoal;

  useEffect(() => {
    if (!goals) return;

    if (!goals.some((goal) => goal.id === goalId)) {
      const newActiveGoal = goals.length > 0 ? goals[0].id : "";
      updateSettings({ activeGoal: newActiveGoal });
    } else {
      updateSettings({ activeGoal: goalId });
    }
  }, [goals, goalId, updateSettings]);

  // Add loading state return
  if (isPending)
    return (
      <div className="w-full h-full flex justify-center items-center p-6">
        <div>
          <Skeleton className="h-4 w-[400px] mb-3" />
          <Skeleton className="h-4 w-[240px] mb-3" />
          <Skeleton className="h-4 w-[500px] mb-2" />
          <Skeleton className="h-4 w-[520px] mb-2" />
          <Skeleton className="h-4 w-[550px] mb-2" />
          <Skeleton className="h-4 w-[510px] mb-2" />
          <Skeleton className="h-4 w-[290px]" />
        </div>
      </div>
    );

  return (
    <div>
      {/* <ShowSidebarButton /> */}
      {/* FIXME: Remove this "weclome state" */}
      {!goalId && (
        <div className="flex items-start justify-center h-full">
          <div className="w-full text-center">
            <img src={emptyStateDashboard} className="block mx-auto w-72" />
            <div className="font-bold text-xl">
              Use the Time Flow System by Managing
              <br />
              your North Stars, Initiatives & Focus Blocks
            </div>
            <Dialog>
              <DialogTrigger className="w-72 mx-auto mt-4 mb-2 rounded-sm bg-gray-100 p-3 text-sm text-gray-600 font-bold text-center hover:bg-gray-200">
                <PlayCircle size="16" className="inline align-middle mr-1" />
                How to use the Time Flow System
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>How to use the Time Flow System</DialogTitle>
                  <DialogDescription className="pt-4">
                    <VimeoVideoEmbed videoId="852462647" title="Welcome to the Time Flow System" />
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>
            <div className="text-gray-500 text-sm">
              <a
                href="https://help.lifehack.org/article/115-what-is-the-time-flow-system"
                target="_blank"
                rel="noreferrer"
                className="text-gray-500 underline"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      )}
      {goalId && (
        <div className="container max-w-[1024px] pt-9">
          <Back link="/northstars" text="Your Northstars" />
          <GoalSection goalId={goalId} />
          <Tabs defaultValue="active" className="mt-12">
            <div className="flex justify-between md:justify-right items-center">
              <div className="mr-4">
                <TabsList>
                  <TabsTrigger value="active">Active</TabsTrigger>
                  <TabsTrigger value="completed">Completed</TabsTrigger>
                </TabsList>
              </div>
              <div>
                <CreateInitiativePopover goalId={goalId} />
              </div>
            </div>
            <TabsContent value="active">
              <ActiveProjects goalId={goalId} />
            </TabsContent>
            <TabsContent value="completed">
              <CompletedProjects goalId={goalId} />
            </TabsContent>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default GoalView;