import { Button } from "@shadcn/button";
import { Popover, PopoverContent, PopoverTrigger } from "@shadcn/popover";
import { Timer } from "lucide-react";
import { useState } from "react";
import { OptionButton } from "./OptionButton";

type DurationPopoverProps = {
  onDurationChange: (duration: number) => void;
};

export const DurationPopover = ({ onDurationChange }: DurationPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [duration, setDuration] = useState(1);

  const generateTimeOptions = (interval: number, maxHours: number) => {
    const options: number[] = [];
    for (let i = interval; i <= maxHours; i += interval) {
      options.push(i);
    }
    return options;
  };

  const timeOptions = generateTimeOptions(0.5, 4);

  return (
    <Popover open={isOpen} onOpenChange={(state) => setIsOpen(state)}>
      <PopoverTrigger>
        <OptionButton>
          <Timer className="inline-block" />
          <span>{duration}hr</span>
        </OptionButton>
      </PopoverTrigger>
      <PopoverContent className="w-auto" align="start">
        <div className="grid grid-cols-4 gap-2 mt-4">
          {timeOptions.map((option) => (
            <Button
              key={option}
              className=""
              variant={duration === option ? "default" : "outline"}
              onClick={() => {
                setDuration(option);
                onDurationChange(option);
                setIsOpen(false);
              }}
            >
              {option}hr{option % 1 === 0.5 ? "s" : ""}
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
