interface StepperProps {
  currentStep: number;
  steps: string[];
}

export function Stepper({ currentStep, steps }: StepperProps) {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0 pt-4 pb-10">
        {steps.map((step, index) => {
          const stepNumber = index + 1
          const isCompleted = stepNumber < currentStep
          const isCurrent = stepNumber === currentStep

          return (
            <li key={index} className="md:flex-1">
              <div
                className={`group flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 ${
                  isCompleted || isCurrent
                    ? "border-primary"
                    : "border-gray-200"
                }`}
                aria-current={isCurrent ? "step" : undefined}
              >
                <span
                  className={`text-sm font-medium ${
                    isCompleted
                      ? "text-slate-500"
                      : isCurrent
                      ? "text-slate-500"
                      : "text-gray-500"
                  }`}
                >
                  Step {stepNumber}
                </span>
                <span
                  className={`text-sm font-medium ${
                    isCompleted || isCurrent ? "text-slate-500" : "text-gray-500"
                  }`}
                >
                  {step}
                </span>
              </div>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}