import { useParams } from "react-router-dom";
import prompts from "./prompts.json";
import { AnswerNewMode } from "./AnswerNewMode";
import { JsonPrompts } from "@shared/types";
import { motion } from "framer-motion";

// const promptsData: {
//   [key: string]: Prompt;
// } = prompts;

export function CreatePromptPage() {
  // get promptID from route param
  const { promptID } = useParams<{ promptID: string }>();
  if (!promptID) return null;

  const promptsData = prompts as JsonPrompts;
  const selectedForm = promptsData[promptID];

  return (
    <motion.div initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 1, scale: 1 }}>
      <AnswerNewMode formInitialData={selectedForm} />
    </motion.div>
  );
}
