import { Button } from "@shadcn/button";
import { Link, useNavigate } from "react-router-dom";
import { useFetchAllPromptAnswers } from "@/queries/promptAnswers";
import { PromptAnswer } from "@shared/types";
import { motion } from "framer-motion";
import { Timestamp } from "firebase/firestore";
import { EmptyState } from "@/web/components/EmptyState";
import { useEffect, useState } from "react";
import { AnswerListLoader } from "./PromptsLoadingIndicators";

function getFormattedDate(dateString: string) {
  // const days = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
  // const months = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  const date = new Date(Date.parse(dateString));

  const dayOfWeek = days[date.getDay()];
  const month = months[date.getMonth()];
  const dayOfMonth = date.getDate().toString().padStart(2, "0");

  return { dayOfWeek, month, dayOfMonth };
}

function getFormattedTime(timestamp: Timestamp) {
  const date = timestamp.toDate();

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
}

export function CompletedPromptsPage() {
  const navigate = useNavigate();
  const { data, isLoading } = useFetchAllPromptAnswers();
  const [groupData, setGroupData] = useState<Array<[string, PromptAnswer[]]>>([]);

  const handleNewPrompt = () => {
    navigate("/prompts/new");
  };

  useEffect(() => {
    if (!data) return;

    // Group promptAnswers by date
    const groupedData: { [key: string]: PromptAnswer[] } = data.reduce(
      (groups: { [key: string]: PromptAnswer[] }, item: PromptAnswer) => {
        const date: Date | undefined = item.createdAt?.toDate();
        if (!date) return groups;

        // Format the date without the time component, considering the user's timezone
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        if (!groups[formattedDate]) {
          groups[formattedDate] = [];
        }
        groups[formattedDate].push(item);

        // Sort the prompts within each date group by createdAt in descending order
        groups[formattedDate].sort((a, b) => {
          if (!a.createdAt || !b.createdAt) return 0;
          const dateA = a.createdAt.toDate();
          const dateB = b.createdAt.toDate();
          return dateB.getTime() - dateA.getTime();
        });

        return groups;
      },
      {}
    );

    // Convert the groupedData object to an array
    const groupedDataArray = Object.entries(groupedData);

    // Sort the array in descending order
    groupedDataArray.sort((a, b) => {
      const dateA = new Date(a[0]);
      const dateB = new Date(b[0]);
      return dateB.getTime() - dateA.getTime();
    });

    setGroupData(groupedDataArray);
  }, [data]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container max-w-[800px]"
    >
      <div className="pt-6 border-b border-gray-200 pb-5 flex items-center justify-between">
        <h3 className="text-2xl font-semibold leading-8 text-gray-900">Your Growth Prompts</h3>
        <div className="mt-3">
          <Button onClick={handleNewPrompt}>Start a new Prompt</Button>
        </div>
      </div>

      {isLoading && (
        <>
          <AnswerListLoader className="my-10" />
          <AnswerListLoader className="my-10" />
          <AnswerListLoader className="my-10" />
        </>
      )}
      {!isLoading && data && groupData && groupData.length === 0 && (
        <EmptyState title="No completed answers yet. Start a new Prompt." />
      )}
      {!isLoading &&
        data &&
        groupData &&
        groupData.map(([date, items]) => {
          const { dayOfWeek, month, dayOfMonth } = getFormattedDate(date);

          return (
            <section key={`${dayOfMonth}-${month}`} className="my-10 flex flex-row flex-wrap">
              <div className="flex items-center w-[150px] mb-4">
                <span className="text-6xl align-text-bottom">{dayOfMonth}</span>
                <div>
                  <div className="ml-2 text-xl">{dayOfWeek}</div>
                  <div className="ml-2 text-2xl">{month}</div>
                </div>
              </div>
              <div className="day-prompt-answers grow w-64">
                {items.map((item) => (
                  <div key={item.id} className="flex items-baseline mb-4">
                    <span className="text-slate-400 mr-2 min-w-10">
                      {item.updatedAt && getFormattedTime(item.updatedAt)}
                    </span>
                    <span className="text-xl">
                      <Link to={`/prompts/${item.id}`}>{item.title}</Link>
                    </span>
                  </div>
                ))}
              </div>
            </section>
          );
        })}
    </motion.div>
  );
}
