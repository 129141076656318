"use client";

import { useState, useEffect } from "react";
import { Button } from "@shadcn/button";
import { Loader2 } from "lucide-react";
import { useOnboardingStore } from "./onboardingStore";
import { useFetchAiOnboardingGoalRecomendations } from "@/queries/ai";
import "@/styles/md-container.css";
import Markdown from "markdown-to-jsx";

interface NorthstarSuggestionProps {
  onAccept: () => void;
  onReject: () => void;
}

export default function NorthstarSuggestion({
  onAccept,
  onReject,
}: NorthstarSuggestionProps) {
  const answers = useOnboardingStore((state) => state.answers);
  const assessmentData = useOnboardingStore((state) => state.assessmentData);
  const setNorthstar = useOnboardingStore((state) => state.setNorthstar);

  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    console.log("northstar-suggestion assessmentData", answers);
    if (answers && assessmentData) {
      const answeredQuestions = assessmentData.questions.map((q, index) => ({
        ...q,
        answer: answers[`question-${index}-${q.type}`] || "",
      }));
      setUserInput(JSON.stringify(answeredQuestions));
    }
  }, [answers, assessmentData]);

  const { data, isLoading, error } =
    useFetchAiOnboardingGoalRecomendations(userInput);

  const handleAccept = () => {
    if (data?.goal) {
      setNorthstar({
        goal: data.goal,
        description: data.description || "",
      });
      onAccept();
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center space-x-2">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <span className="text-lg font-medium text-primary">
          Generating Northstar...
        </span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500">
        Error generating Northstar. Please try again.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Markdown className="md-container">
        {`
# Step 3: Your Suggested Big Northstar

After carefully analyzing your 360 Assessment responses, we've crafted a Big Northstar goal tailored just for you:
`}
      </Markdown>
      <div className="bg-primary/10 p-4 rounded-md space-y-2">
        {data?.goal ? (
          <>
            <p className="text-3xl font-semibold text-primary">{data.goal}</p>
          </>
        ) : (
          <p className="text-gray-500">No Northstar suggestion available.</p>
        )}
      </div>
      <Markdown className="md-container">{data?.description || ""}</Markdown>
      <Markdown className="md-container">
        {`
---

### Is this Big Northstar truly inspiring and aligned with your vision?**

Your Northstar Goal is the compass for your entire journey. It's crucial to get this right, so take your time and don't hesitate to refine it.
`}
      </Markdown>
      <div className="space-x-4">
        <Button
          onClick={handleAccept}
          className="bg-primary hover:bg-primary/90"
        >
          This Resonates - Let&apos;s Generate Report
        </Button>
        <Button
          variant="outline"
          onClick={onReject}
          className="border-primary text-primary"
        >
          I Want to Refine This
        </Button>
      </div>
      <Markdown className="md-container">
        {`
Clicking "**This Resonates - Let's Generate Report**" will take you to a deep-dive session where we'll break down this goal and ensure it truly fits your aspirations.

Clicking "**I Want to Refine This**" will allow you to adjust your assessment answers or provide more details to shape a goal that excites you.

Remember, this is an iterative process. Feel free to go back and forth between refining your answers and exploring different Northstar Goals. The aim is to create a goal that genuinely inspires and motivates you. Take as much time as you need - this step is the foundation of your success!
`}
      </Markdown>
    </div>
  );
}
