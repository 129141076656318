import { ReactElement } from 'react';

interface RenderTagProps {
  label: string;
  className?: string;
}

export function renderTag({ label, className = '' }: RenderTagProps): ReactElement {
  return (
    <span className={`px-1 py-1 bg-secondary text-white ${className}`}>
      {label}
    </span>
  );
}
