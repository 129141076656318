import { PromptAnswer } from "@shared/types";
import { PromptResponse } from "@shared/types";

interface ExtendedPrompt extends PromptAnswer {
  firstName?: string;
}

export const cleanUpPromptAnswer = (promptAnswer: ExtendedPrompt, firstName: string): string => {
  // Create a new object with only the properties you want
  const modifiedPromptAnswer = {
    ...promptAnswer,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    questions: promptAnswer.questions.map(({ placeholder, type, instruction, ...rest }) => rest),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    responses: undefined, // also remove responses from the prompt answer. Too big to send to the AI
  };

  // Remove createdAt and updatedAt
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { createdAt, updatedAt, aiResponse, ...finalPromptAnswer } = modifiedPromptAnswer;

  if (firstName) {
    finalPromptAnswer.firstName = firstName;
  }

  // As we are sending the prompt to the AI and generate consistant hash, we need to sort the keys
  const sortedObject = (obj: Record<string, unknown> | unknown[]): Record<string, unknown> | unknown[] => {
    if (Array.isArray(obj)) {
      return obj.map((item) => sortedObject(item as Record<string, unknown> | unknown[]));
    } else if (obj !== null && typeof obj === "object") {
      return Object.keys(obj)
        .sort()
        .reduce(
          (result, key) => {
            const value = (obj as Record<string, unknown>)[key];
            if (Array.isArray(value)) {
              result[key] = sortedObject(value);
            } else if (value !== null && typeof value === "object") {
              result[key] = sortedObject(value as Record<string, unknown>);
            } else {
              result[key] = value;
            }
            return result;
          },
          {} as Record<string, unknown>
        );
    }
    return obj;
  };

  // console.log(JSON.stringify(finalPromptAnswer));
  return JSON.stringify(sortedObject(finalPromptAnswer));
};

export function populatePromptResponse(responseTypeId: string, title: string, answer: string) {
  return {
    fromType: "ai",
    responseTypeId,
    title,
    answer,
  } as PromptResponse;
}
