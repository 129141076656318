import { useKeypress } from "@/hooks/useKeypress";
import { useAddPromptAnswer, useEmailHumanCoach } from "@/queries/promptAnswers";
import { Prompt } from "@shared/types";
import { Button } from "@shadcn/button";
import { Form } from "@shadcn/form";
import { PromptQuestion } from "@shared/components/prompts/question";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// TODO: refactor this component
// Now if calling from dashboard, we don't need to show the category and prompt title, and redirection after completion is different

interface AnswerNewModeProps {
  formInitialData: Prompt;
  isCallFromDashboard?: boolean;
}

export const AnswerNewMode = ({ formInitialData: prompt, isCallFromDashboard }: AnswerNewModeProps) => {
  const navigate = useNavigate();
  const form = useForm();
  // const [prompt, setPrompt] = useState<Prompt>(formInitialData);
  const { mutateAsync: addAnswer, isError, error } = useAddPromptAnswer();
  const { mutate: emailHumanCoach } = useEmailHumanCoach();

  const handleSave = async (values: Record<string, string>) => {
    // FIXME: find a better way to handle untouched scale answer
    const questions = prompt.questions.map((q, index) => ({
      ...q,
      answer: values[`question-${index}-${q.type}`] || "",
    }));

    console.log(questions);

    // FIXME: Black magic, if isCallFromDashboard is true, we assume they need human coach
    const answerData = isCallFromDashboard
      ? { ...prompt, questions, isWaitingHumanCoach: true }
      : { ...prompt, questions };

    const docRef = await addAnswer(answerData);
    console.log("Document written with ID: ", docRef.id);

    if (isCallFromDashboard) {
      emailHumanCoach({ promptId: docRef.id, question: "Personalize a 360 Report for this user." });
      navigate(`/`, { replace: true });
    } else {
      navigate(`/prompts/${docRef.id}`, { replace: true });
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent form submission
    navigate(-1);
  };

  useKeypress("Escape", () => navigate(-1));

  // const handleAnswerChange = (index: number) => (newAnswer: string) => {
  //   setPrompt((prevForm) => {
  //     const newForm = { ...prevForm };
  //     newForm.questions[index].answer = newAnswer;
  //     return newForm;
  //   });
  // };

  // In case of an error
  if (isError && error) {
    console.error("Failed to add prompt answer:", error.message);
  }

  return (
    <div className="container pb-10">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSave)}>
          {!isCallFromDashboard && (
            <>
              <div className="flex justify-between items-center">
                <span className="text-lg mt-10 text-gray-500">{prompt.category}</span>
                <div>
                  <Button className="mt-10 mr-4" type="submit">
                    Save
                  </Button>
                  <Button className="mt-10" variant="outline" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
              <h1 className="text-4xl mt-2 mb-2 text-primary">{prompt.title}</h1>
              <p className="text-gray-500">{prompt.description}</p>
            </>
          )}
          <div className="flex flex-col gap-8 my-16">
            {prompt.questions.map((question, index) => (
              <PromptQuestion key={`${question.question}-${index}`} question={question} index={index} />
            ))}
          </div>
          <div>
            <Button className="mt-10 mr-4" type="submit">
              Save
            </Button>
            {!isCallFromDashboard && (
              <Button className="mt-10" variant="outline" onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
};
