import React from 'react';
import LoadingIndicator from './LoadingIndicator';

const LoadingIndicatorFullPage: React.FC = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75">
      <LoadingIndicator />
    </div>
  );
};

export default LoadingIndicatorFullPage;
